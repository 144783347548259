import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import DescriptionIcon from "@material-ui/icons/Description";

import ActivityLog from "../../pages/pages/ActivityLog";

export default function PartnerAccountsAppTable({ schools }) {
  const [selectedData, setSelectedData] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const cols = [
    {
      field: "id",
      headerName: "School Id",
      flex: 0.2,
      minWidth: 100,
    },
    {
      field: "schoolName",
      headerName: "School Name",
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: "version",
      headerName: "Version",
      width: 100,
    },

    {
      field: "action",
      headerName: "Action",
      with: 100,
      renderCell: (params) => {
        const handleShowDetail = () => {
          setShowLogs(true);
          setSelectedData(params.row);
        };

        return (
          <Tooltip title="View Logs">
            <IconButton onClick={handleShowDetail}>
              <DescriptionIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const handleShowLogs = () => {
    setShowLogs(false);
  };

  return (
    <>
      {showLogs && (
        <ActivityLog data={selectedData} handleShowLogs={handleShowLogs} />
      )}
      {!showLogs && (
        <div style={{ height: 470, width: "100%" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },

              "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
            rows={schools}
            columns={cols}
            rowsPerPageOptions={[10, 50, 100]}
            disableSelectionOnClick
            scrollbarSize={30}
            onRowDoubleClick={(e) => {
              setSelectedData(e.row);
              setShowLogs(true);
            }}
            hideFooter
          />
        </div>
      )}
    </>
  );
}
