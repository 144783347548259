import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import Moment from "react-moment";

import {
  Box,
  Button as MuiButton,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { setPartnerStatus } from "../../redux/actions/partnerActions";
import PartnerStatus from "../../components/PartnerStatus";
import api from "../../api/api";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";

const Button = styled(MuiButton)(spacing);

const Centered = styled.div`
  width: 100%;

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px 2rem 10px;
  margin: 1rem 0;
`;

const InnerBox = styled.div`
  text-align: center;
  display: flex;
  justify-content: flex-start;
`;
const Buttons = styled.div`
  display: flex;
`;

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    minHeight: "500px",
    padding: " 1rem 0",
    background: "white",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
    [theme.breakpoints.down("960")]: {
      width: "100%",
      padding: "2rem 0",
    },
    // [theme.breakpoints.down("600")]: {
    //   width: "80%",
    // },
  },
  successButton: {
    color: "white",
    width: "80px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },
  rejectButton: {
    color: "white",
    width: "80px",
    background: "#c71414",
    "&:hover": {
      background: "#ff0000",
    },
  },
}));

function PartnerRequest({ handleClose }) {
  const [partner, setPartner] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSending, setIsSending] = useState({ accept: false, reject: false });
  const dispatch = useDispatch();
  const partnerState = useSelector((state) => state.partnerReducer);
  const auth = useSelector((state) => state.authReducer);

  const { partnerStatus } = partnerState;
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  useEffect(() => {
    api
      .get(`api/Partner/GetPartner/${partnerState?.partner.id}?v=1.0`, {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setPartner(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  }, [partnerStatus]);

  const acceptPartnerHandler = async () => {
    setIsSending({ accept: true, reject: false });

    api
      .post(`api/Partner/AcceptPartner/${partner?.id}?v=1.0`)
      .then((response) => {
        if (response.status === 200) {
          setIsSending({ accept: false, reject: false });
          dispatch(setPartnerStatus(!partnerStatus));
          handleClose();
          enqueueSnackbar("Partner activated.", {
            variant: "success",
            autoHideDuration: 3000,
          });
        }
      })
      .catch((error) => {
        setIsSending({ accept: false, reject: false });
        setError(error.message);
      });

    handleClose();
  };
  const rejectPartnerHandler = async () => {
    setIsSending({ accept: false, reject: true });
    api
      .post(`api/Partner/RejectPartner/${partner?.id}?v=1.0`)
      .then((response) => {
        if (response.status === 200) {
          setIsSending({ accept: false, reject: false });
          dispatch(setPartnerStatus(!partnerStatus));
          handleClose();
          enqueueSnackbar("Partner deactivated.", {
            variant: "success",
            autoHideDuration: 3000,
          });
        }
      })
      .catch((error) => {
        setIsSending({ accept: false, reject: false });
        setError(error.message);
      });
  };

  if (isLoading) return <Loader />;
  if (error) return <div style={{ color: "red" }}>{error}</div>;
  return (
    <Box className={classes.container}>
      <Box marginBottom={5}>
        <Typography
          id="modal-modal-title"
          variant="h3"
          component="h2"
          gutterBottom
          align="center"
        >
          Partner Request
        </Typography>
      </Box>
      <Centered>
        <InnerBox>
          <Box component="b" marginRight={2} marginBottom={2} textAlign="left">
            Organization:
          </Box>
          <Box
            component="span"
            marginRight={2}
            marginBottom={2}
            textAlign="left"
          >
            {partner.organizationName}
          </Box>
        </InnerBox>
        <InnerBox>
          <Box component="b" marginRight={2} marginBottom={2} textAlign="left">
            First Name:
          </Box>
          <Box
            component="span"
            marginRight={2}
            marginBottom={2}
            textAlign="left"
          >
            {partner.firstName}
          </Box>
        </InnerBox>
        <InnerBox>
          <Box component="b" marginRight={2} marginBottom={2} textAlign="left">
            Last Name:
          </Box>
          <Box
            component="span"
            marginRight={2}
            marginBottom={2}
            textAlign="left"
          >
            {partner.lastName}
          </Box>
        </InnerBox>
        <InnerBox>
          <Box component="b" marginRight={2} marginBottom={2} textAlign="left">
            Email:
          </Box>
          <Box
            component="span"
            marginRight={2}
            marginBottom={2}
            textAlign="left"
          >
            {partner.email}
          </Box>
        </InnerBox>
        <InnerBox>
          <Box component="b" marginRight={2} marginBottom={2} textAlign="left">
            Telephone Number :
          </Box>
          <Box
            component="span"
            marginRight={2}
            marginBottom={2}
            textAlign="left"
          >
            {partner.phone}
          </Box>
        </InnerBox>
        <InnerBox>
          <Box component="b" marginRight={2} marginBottom={2} textAlign="left">
            Website:
          </Box>
          <Box
            component="span"
            marginRight={2}
            marginBottom={2}
            textAlign="left"
          >
            {partner.webSite}
          </Box>
        </InnerBox>
        <InnerBox>
          <Box component="b" marginRight={2} marginBottom={2} textAlign="left">
            Role:
          </Box>
          <Box
            component="span"
            marginRight={2}
            marginBottom={2}
            textAlign="left"
          >
            {partner.role}
          </Box>
        </InnerBox>
        <InnerBox>
          <Box component="b" marginRight={2} marginBottom={2} textAlign="left">
            No of Bromcom Customers:
          </Box>
          <Box
            component="span"
            marginRight={2}
            marginBottom={2}
            textAlign="left"
          >
            {partner.customerCount}
          </Box>
        </InnerBox>
        <InnerBox>
          <Box component="b" marginRight={2} marginBottom={2} textAlign="left">
            Services Provided to Bromcom Customers:
          </Box>
          <Box
            component="span"
            marginRight={2}
            marginBottom={2}
            textAlign="left"
          >
            {partner.servicesProvidedToBC}
          </Box>
        </InnerBox>
        <InnerBox>
          <Box component="b" marginRight={2} marginBottom={2} textAlign="left">
            Planned Integrations:
          </Box>
          <Box
            component="span"
            marginRight={2}
            marginBottom={2}
            textAlign="left"
          >
            {partner.plannedIntegrations}
          </Box>
        </InnerBox>
        <InnerBox>
          <Box component="b" marginRight={2} marginBottom={2} textAlign="left">
            Created Date:
          </Box>
          <Box
            component="span"
            marginRight={2}
            marginBottom={2}
            textAlign="left"
          >
            <Moment
              date={partner?.partner?.createdDate}
              format="DD/MM/YYYY HH:mm:ss"
            />
          </Box>
        </InnerBox>
        <InnerBox>
          <Box component="b" marginRight={2} marginBottom={2} textAlign="right">
            Status:
          </Box>
          <Box
            component="span"
            marginRight={2}
            marginBottom={2}
            textAlign="left"
          >
            <PartnerStatus
              partnerStatusId={partner.partnerStatusId}
              isBadge={false}
            />
          </Box>
        </InnerBox>
      </Centered>
      <Buttons>
        <Button
          mr={2}
          variant="contained"
          className={classes.successButton}
          onClick={acceptPartnerHandler}
          disabled={isSending.reject}
        >
          {isSending.accept && !isSending.reject ? (
            <CircularProgress size={18} style={{ color: "white" }} />
          ) : (
            "Accept"
          )}
        </Button>
        <Button
          variant="contained"
          className={classes.rejectButton}
          onClick={rejectPartnerHandler}
          disabled={isSending.accept}
        >
          {!isSending.accept && isSending.reject ? (
            <CircularProgress size={18} style={{ color: "white" }} />
          ) : (
            "Reject"
          )}
        </Button>
      </Buttons>
    </Box>
  );
}

export default PartnerRequest;
