import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";

import { makeStyles } from "@material-ui/styles";

import QuickSearchToolbar from "./QuickSearchToolbar";
import Moment from "react-moment";

import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SchoolIcon from "@material-ui/icons/School";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import PageviewIcon from "@material-ui/icons/Pageview";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";

import RegisteredSchools from "../../pages/pages/RegisteredSchools";
import PermissionPage from "../../pages/pages/Permisson";
import Audit from "../../pages/pages/Audit";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "600px",
  },
  cancelIconContainer: {
    position: "relative",
  },
  cancelIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.6",
    },
  },
  cancelIconForAudit: {
    cursor: "pointer",
    "&:hover": {
      opacity: "0.6",
    },
  },

  detailButton: {
    marginRight: "5px",
  },
  schoolButton: {
    marginRight: "5px",
  },
  cancelButton: {
    color: "white",
    padding: "8px 20px",
    background: "#c71414",
    "&:hover": {
      background: "#ff0000",
    },
  },
  dialogTitle: {
    padding: "8px 24px 8px 35px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const Badge = styled.span`
  font-size: 11px;
  background-color: ${(props) => props.color};
  color: white;
  border-radius: 8px;
  padding: 4px 8px;
  text-align: center;
`;

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default function ApplicationTable({ applications, isSubmitted }) {
  const [selectedApp, setSelectedApp] = useState({});
  const [selectedAppId, setSelectedAppId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(applications);
  const [showSchools, setshowSchools] = useState(false);
  const [showVersions, setShowVersions] = useState(false);
  const [showAudit, setShowAudit] = useState(false);

  const history = useHistory();

  const auth = useSelector((state) => state.authReducer);

  const [sortModel, setSortModel] = useState([
    {
      field: "createdDate",
      sort: "desc",
    },
  ]);

  const classes = useStyles();

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");

    const filteredRows = applications.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });

    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(applications);
  }, [applications]);

  const handleCloseVersions = () => {
    setShowVersions(false);
  };

  const handleCloseAudit = () => {
    setShowAudit(false);
  };

  const handleCloseSchools = () => {
    setshowSchools(false);
  };

  const cols = [
    // { field: "id", headerName: "id", minWidth: 200 },
    { field: "name", headerName: "Application Name", flex: 1, minWidth: 200 },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 120,
    },
    {
      field: "lastVersionId",
      headerName: "Latest Version",
      width: 150,
      align: "center",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Moment date={params.value} format="DD/MM/YYYY HH:mm:ss" />
      ),
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      flex: 1,
      minWidth: 150,

      renderCell: (params) => (
        <Moment date={params.value} format="DD/MM/YYYY HH:mm:ss" />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      align: "center",
      renderCell: (params) => {
        if (params.value === "Publish")
          return <Badge color="#8AC747">{params.value}</Badge>;
        else if (params.value === "Unpublish")
          return <Badge color="#9c9c9c">{params.value}</Badge>;
        else if (params.value === "Blocked")
          return <Badge color="#EF233C">{params.value}</Badge>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      minWidth: 180,
      align: "center",

      renderCell: (params) => {
        const handleShowDetail = () => {
          history.push(`/applications/${params.row.id}`);
        };

        const handleShowSchools = () => {
          setSelectedApp({
            appId: params.row.id,
            versions: params.row.versions,
            versionId: params.row.versions[0].id,
            applicationSecret: params.row.versions[0].applicationSecret,
          });

          setshowSchools(true);
        };

        const handleShowVersions = () => {
          setSelectedApp({
            appId: params.row.id,
            versions: params.row.versions,
            applicationSecret: params.row.versions[0].applicationSecret,
            applicationId: params.row.applicationId,
          });

          setShowVersions(true);
        };

        const handleShowAudit = () => {
          setSelectedAppId(params.row.id);
          setShowAudit(true);
        };

        return (
          <>
            {auth?.user?.rol === "PartnerAccountOwner" ||
            auth?.user?.rol === "PartnerAdmin" ||
            auth?.user?.rol === "PartnerDeveloper" ? (
              <Tooltip title="View Application Detail">
                <div>
                  <IconButton
                    onClick={handleShowDetail}
                    disabled={params.row?.status === "Blocked" ? true : false}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </div>
              </Tooltip>
            ) : null}

            <Tooltip title="View Registered Schools">
              <div>
                <IconButton
                  onClick={handleShowSchools}
                  disabled={params.row?.status === "Blocked" ? true : false}
                >
                  <SchoolIcon />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip title="View Versions">
              <div>
                <IconButton
                  onClick={handleShowVersions}
                  disabled={params.row?.status === "Blocked" ? true : false}
                >
                  <ViewModuleIcon />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip title="View Audit">
              <div>
                <IconButton
                  onClick={handleShowAudit}
                  disabled={params.row?.status === "Blocked" ? true : false}
                >
                  <PageviewIcon />
                </IconButton>
              </div>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      {showSchools && !showVersions && !showAudit && (
        <Dialog
          open={showSchools}
          onClose={handleCloseSchools}
          maxWidth="md"
          fullWidth
        >
          <Box className={classes.cancelIconContainer}>
            <CloseIcon
              onClick={handleCloseSchools}
              className={classes.cancelIcon}
            />
          </Box>

          <DialogContent className={classes.container}>
            <RegisteredSchools selectedApp={selectedApp} />
          </DialogContent>
        </Dialog>
      )}
      {!showSchools && showVersions && !showAudit && (
        <Dialog
          open={showVersions}
          onClose={handleCloseVersions}
          maxWidth="md"
          fullWidth
        >
          <Box className={classes.cancelIconContainer}>
            <CloseIcon
              onClick={handleCloseVersions}
              className={classes.cancelIcon}
            />
          </Box>

          <DialogContent className={classes.container}>
            <PermissionPage selectedApp={selectedApp} />
          </DialogContent>
        </Dialog>
      )}
      {!showSchools && !showVersions && showAudit && (
        <Dialog
          open={showAudit}
          onClose={handleCloseAudit}
          maxWidth="md"
          fullWidth
        >
          <Box className={classes.dialogTitle}>
            <h3>Audit</h3>
            {/* <Box className={classes.cancelIconContainer}> */}
            <CloseIcon
              onClick={handleCloseAudit}
              className={classes.cancelIconForAudit}
            />
          </Box>

          <DialogContent className={classes.container}>
            <Audit appId={selectedAppId} />
          </DialogContent>
        </Dialog>
      )}
      <div style={{ height: 760 }}>
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },

            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
          rows={rows}
          columns={cols}
          sortModel={sortModel}
          rowsPerPageOptions={[10, 50, 100]}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(" "),
            },
          }}
          onSortModelChange={(model) => setSortModel(model)}
          disableSelectionOnClick
          scrollbarSize={30}
          onRowDoubleClick={(e) => {
            if (
              e.row.status === "Blocked" ||
              auth?.user.rol === "PartnerSupport"
            )
              return;
            else {
              history.push(`/applications/${e.row.id}`);
            }
          }}
          hideFooter
        />
      </div>
    </>
  );
}
