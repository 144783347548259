import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Checkbox } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";

import { setScopes } from "../../redux/actions/scopeActions";
import QuickSearchToolbar from "./QuickSearchToolbar";
import PropTypes from "prop-types";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

function ApiScopesTable({ scopes }) {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState("");

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");

    const filteredRows = scopes.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });

    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(scopes);
  }, [scopes]);

  useEffect(() => {
    const selectedRows = rows.filter(
      (row) => !!row.get || !!row.post || !!row.put || !!row.delete
    );
    dispatch(setScopes(selectedRows));
  }, [rows]);

  const handleSelect = (row) => {
    const newRows = [...rows];
    const index = newRows.findIndex((r) => r.id === row.id);
    newRows[index] = { ...row };
    setRows(newRows);
  };
  const cols = [
    {
      field: "name",
      headerName: "Endpoint Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "get",
      headerName: "Get",
      flex: 0.5,
      renderCell: ({ row }) => {
        return (
          <Checkbox
            inputProps={{ "aria-label": "primary checkbox" }}
            checked={row.get}
            onChange={() => handleSelect({ ...row, get: !row.get })}
          />
        );
      },
    },

    {
      field: "post",
      headerName: "Post",
      flex: 0.5,
      renderCell: ({ row }) => {
        if (!row.isPostEnable) {
          return "";
        } else {
          return (
            <Checkbox
              inputProps={{ "aria-label": "primary checkbox" }}
              checked={row.post}
              onChange={() => handleSelect({ ...row, post: !row.post })}
            />
          );
        }
      },
    },
    {
      field: "put",
      headerName: "Put",
      flex: 0.5,
      renderCell: ({ row }) => {
        if (!row.isPutEnable) {
          return "";
        }
        return (
          <Checkbox
            inputProps={{ "aria-label": "primary checkbox" }}
            checked={row.put}
            onChange={() => handleSelect({ ...row, put: !row.put })}
          />
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 0.5,
      renderCell: ({ row }) => {
        if (!row.isDeleteEnable) {
          return "";
        }
        return (
          <Checkbox
            inputProps={{ "aria-label": "primary checkbox" }}
            checked={row.delete}
            onChange={() => handleSelect({ ...row, delete: !row.delete })}
          />
        );
      },
    },
  ];

  return (
    <div style={{ height: 470 }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },

          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        components={{ Toolbar: QuickSearchToolbar }}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(" "),
          },
        }}
        rows={rows}
        columns={cols}
        disableSelectionOnClick
        scrollbarSize={30}
      />
    </div>
  );
}

export const MemoizedApiScopesTable = React.memo(ApiScopesTable);
