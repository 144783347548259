// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_IN_FAILED = "AUTH_SIGN_IN_FAILED";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

//Partner
export const PARTNER_REQUEST = "PARTNER_REQUEST";
export const PARTNER_SUCCESS = "PARTNER_SUCCESS";
export const PARTNER_FAILURE = "PARTNER_FAILURE";
export const PARTNER_SET = "PARTNER_SET";
export const PARTNER_STATUS = "PARTNER_STATUS";
export const PARTNERS_GET = "PARTNERS_GET";

//Grid Info
export const GRID_ROWS_PER_PAGE = "GRID_ROWS_PER_PAGE";
export const GRID_PAGE = "GRID_PAGE";
export const GRID_ISLOADING = "GRID_ISLOADING";

//Scopes
export const SCOPE_ADD = "SCOPE_ADD";
export const SCOPES_RESET = "SCOPES_RESET";
export const SCOPES_ALL = "SCOPES_ALL";
