import React from "react";

import { DataGrid } from "@mui/x-data-grid";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Moment from "react-moment";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  backButton: {
    marginTop: "20px",
  },
}));

export default function ActivityLogTable({ activityLogs, handleShowLogs }) {
  const classes = useStyles();
  const cols = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
    },
    {
      field: "schoolId",
      headerName: "SchoolId",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "endPointName",
      headerName: "Scope",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "method",
      headerName: "Method",
      width: 80,
    },
    {
      field: "isAuthenticatedDescription",
      headerName: "IsAuthenticated",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "instant",
      headerName: "Instant",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Moment date={params.value} format="DD/MM/YYYY HH:mm:ss" />
      ),
    },
  ];

  return (
    <Box className={classes.container}>
      <div style={{ height: 470, width: "100%" }}>
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },

            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
          rows={activityLogs}
          columns={cols}
          rowsPerPageOptions={[10, 50, 100]}
          disableSelectionOnClick
          scrollbarSize={30}
        />
      </div>
      <Button
        variant="contained"
        className={classes.backButton}
        startIcon={<ArrowBackIcon />}
        onClick={() => handleShowLogs()}
      >
        Back
      </Button>
    </Box>
  );
}
