import * as types from "../../constants";

const initialState = {
  scopes: [],
};

export default function reducer(state = initialState, actions) {
  let scopes = [...state.scopes];

  switch (actions.type) {
    case types.SCOPE_ADD:
      return {
        ...state,
        scopes: scopes,
      };
    case types.SCOPES_RESET:
      return {
        ...state,
        scopes: actions.scopes,
      };

    default:
      return state;
  }
}
