import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Form, Formik } from "formik";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Cancel as CancelIcon,
  CloudUpload as MuiCloudUpload,
} from "@material-ui/icons";
import api from "../../api/api";
import { spacing } from "@material-ui/system";
import AttachedFile from "../../components/AttachedFile";
import { useHistory } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const useStyles = makeStyles((theme) => ({
  attachedFile: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    margin: "0 4px",
  },
  cancelIcon: {
    cursor: "pointer",
    position: "absolute",
    top: "-10px",
    right: "-5px",
    transform: "scale(.7)",
    color: "#c71414",
    "&:hover": {
      color: "#ff0000",
    },
  },
  uploadButton: {
    color: "white",
    padding: "5px 10px",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
  },
  submitButton: {
    color: "white",
    padding: "8px 20px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },
  cancelButton: {
    color: "white",
    padding: "8px 20px",
    background: "#c71414",
    "&:hover": {
      background: "#ff0000",
    },
  },
  imagesContainer: {
    height: "55px",
    padding: "0 5px",

    display: "flex",
    alignItems: "center",
  },
  attachedImage: {
    height: "30px",
    width: "70px",
    objectFit: "cover",
    border: "2px solid #ddd",
    cursor: "pointer",
    marginRight: "5px",

    "&:hover": {
      border: "2px solid #a6a6a6",
    },
  },
}));

const initialValues = {
  title: "",
  description: "",
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

function CreateTicket() {
  const classes = useStyles();
  const [attachedFiles, setAttachedFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const auth = useSelector((state) => state.authReducer);
  const history = useHistory();

  const handleCancelTicket = () => {
    history.push("/supporttickets");
  };

  const handleFileChange = (e) => {
    const newFiles = Object.values(e.target.files);
    const filteredAttachedFiles = newFiles.filter((file) =>
      file.name.match(/\.(jpg|jpeg|png|doc|docx|xls|xlsx|txt|pdf)$/i)
    );

    const newFilteredAttachedFiles = attachedFiles.concat(
      filteredAttachedFiles.filter(
        ({ name }) => !attachedFiles.find((f) => f.name == name)
      )
    );

    if (attachedFiles.length > 0) {
      setAttachedFiles(newFilteredAttachedFiles);
    } else {
      setAttachedFiles(filteredAttachedFiles);
    }
    e.target.value = null;
  };

  const handleCancelAttachedFile = (e) => {
    const newAttachedFiles = attachedFiles.filter((file) => file.name !== e);
    setAttachedFiles(newAttachedFiles);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        let aFiles = [];
        let promises = [];

        const createError = (error) => {
          setStatus({ sent: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        };

        const createSubmit = async () => {
          return api
            .post(
              "api/SupportTicket/Create?v=1.0",
              {
                title: values.title,
                description: values.description,
                attachedFiles: aFiles,
              },
              {
                headers: {
                  Authorization: `Bearer ${auth.user?.token}`,
                },
              }
            )
            .then((response) => {
              setSubmitting(false);
              handleCancelTicket();
              enqueueSnackbar("Ticket created successfully", {
                variant: "success",
                autoHideDuration: 3000,
              });
            });
        };

        attachedFiles.forEach((file) => {
          const formData = new FormData();
          formData.append("File", file);
          promises.push(
            api
              .post("api/File/upload?v=1.0", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${auth.user?.token}`,
                },
              })
              .then((response) => {
                aFiles.push({
                  id: response.data.data.id,
                  path: response.data.data.path,
                  mimeType: file.type,
                });
              })
          );
        });

        if (promises.length > 0) {
          Promise.all(promises).then(createSubmit).catch(createError);
        } else {
          createSubmit().catch(createError);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <>
          <Helmet title="Support Tickets" />

          <Grid justify="space-between" container spacing={4}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Support Tickets
              </Typography>

              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Typography>Pages</Typography>
                <Typography>Support Tickets</Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item></Grid>
          </Grid>
          <Divider my={6} />
          <Form onSubmit={handleSubmit}>
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Create New Ticket
                </Typography>

                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    id="title"
                    label="Title"
                    value={values.title}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    my={2}
                    disabled={isSubmitting}
                  />
                </FormControl>
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    id="description"
                    label="Description"
                    value={values.description}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    multiline={true}
                    rows={5}
                    rowsMax={6}
                    variant="outlined"
                    disabled={isSubmitting}
                  />
                </FormControl>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <Box className={classes.imagesContainer}>
                      <input
                        accept=".xlsx,.xls,image/*,.doc, .docx,.txt,.pdf"
                        style={{ display: "none" }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          className={classes.uploadButton}
                          disabled={isSubmitting}
                        >
                          <CloudUpload mr={2} /> Upload
                        </Button>
                      </label>

                      {attachedFiles.map((file) => (
                        <Box
                          component="span"
                          className={classes.attachedFile}
                          key={file.name}
                        >
                          <CancelIcon
                            className={classes.cancelIcon}
                            onClick={handleCancelAttachedFile.bind(
                              this,
                              file?.name
                            )}
                          />
                          <AttachedFile file={file} />
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12} alignItems="flex-end">
                    <Box className={classes.buttons}>
                      <Button
                        type="submit"
                        mr={2}
                        variant="contained"
                        className={classes.submitButton}
                      >
                        {!isSubmitting && "Submit"}
                        {isSubmitting && (
                          <CircularProgress
                            size={18}
                            style={{ color: "white" }}
                          />
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={handleCancelTicket}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default CreateTicket;
