import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import api from "../../api/api";
import Loader from "../../components/Loader";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Moment from "react-moment";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",

    padding: " 0 20px 30px 20px",
    minHeight: "450px",
  },
  loaderContainer: {
    minHeight: "450px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  commentAvatar: {
    height: "42px",
    width: "42px",
    color: "#a8a8a8",
    marginRight: "5px",
  },
  audit: {
    display: "flex",
    marginBottom: "10px",
    padding: "5px 0",
    borderTop: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
  },
  auditTexts: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "3px",
  },
}));

function Audit({ appId }) {
  const [audits, setAudits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const auth = useSelector((state) => state.authReducer);

  const classes = useStyles();

  // const handleCloseWarningMessage = () => {
  //   setShowWarningMessage(false);
  // };

  useEffect(() => {
    api
      .get(
        `api/AuditTrail/${appId}/List?v=1.0`,

        {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setAudits(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  }, []);

  if (error) return <div style={{ color: "red" }}>{error}</div>;
  return (
    <Box className={classes.container}>
      {isLoading && (
        <Box className={classes.loaderContainer}>
          <Loader />
        </Box>
      )}
      {!isLoading && (
        <>
          {audits.map((audit) => (
            <Box className={classes.audit}>
              <AccountCircle className={classes.commentAvatar} />
              <Box className={classes.auditTexts}>
                <Box>
                  <span>
                    {audit.fullName}{" "}
                    <Moment
                      date={audit.dateTime}
                      format="DD/MM/YYYY HH:mm:ss"
                    />
                  </span>
                </Box>
                <Box>{audit.description}</Box>
              </Box>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
}

export default Audit;
