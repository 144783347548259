import { Box, Dialog, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "530px",
    padding: "30px",
  },
  cancelIconContainer: {
    position: "relative",
  },
  cancelIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.6",
    },
  },
}));
function CustomModal({ open, handleClose, bp, children }) {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={bp} fullWidth>
      <Box className={classes.cancelIconContainer}>
        <CloseIcon onClick={handleClose} className={classes.cancelIcon} />
      </Box>
      <DialogContent className={classes.container}>{children}</DialogContent>
    </Dialog>
  );
}

export default CustomModal;
