import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { signIn } from "../../redux/actions/authActions";
import AppLogo from "../../components/AppLogo";

import {
  Box,
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Card as MuiCard,
  CardContent,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Card = styled(MuiCard)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
  background-color: #f9f8fd;
`;

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0 1px 5px 2px rgba(0, 0, 0, .2)",
    padding: "1rem",
  },
  signInButton: {
    color: "white",
    backgroundColor: "#1D88E4",
    "&:hover": { backgroundColor: "#176dea" },
  },

  forgotButton: {
    marginTop: "1.2rem",
    color: "#1D88E4",
    "&:hover": { color: "#176dea", background: "none" },
  },
}));

function SignIn() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const auth = useSelector((state) => state.authReducer);

  return (
    <Wrapper>
      <Helmet title="Sign In" />
      <AppLogo mb="20px" />
      <Card className={classes.card}>
        <CardContent>
          <Typography component="h1" variant="h2" align="center" gutterBottom>
            Welcome back!
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Sign in to your account to continue
          </Typography>

          <Formik
            initialValues={{
              email: "",
              password: "",
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await dispatch(
                  signIn({
                    username: values.email,
                    password: values.password,
                    type: "partner",
                  })
                );
              } catch (error) {
                const message = error.message || "Something went wrong";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                {!auth?.isSuccess && auth?.message === "Wrong Password" && (
                  <Alert mt={2} mb={1} severity="warning">
                    The password you entered is incorrect. Please try again.
                  </Alert>
                )}
                {!auth?.isSuccess && auth?.message === "User Not Exist" && (
                  <Alert mt={2} mb={1} severity="warning">
                    No users found with this email address. Please check your
                    email address.
                  </Alert>
                )}
                {!auth?.isSuccess && auth?.message === "User Not Active" && (
                  <Alert mt={2} mb={1} severity="warning">
                    The account with this email is deactivated. Contact to{" "}
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location = "mailto:Partner.Portal@bromcom.com";
                        e.preventDefault();
                      }}
                    >
                      Partner.Portal@bromcom.com
                    </Link>
                  </Alert>
                )}
                <TextField
                  type="email"
                  name="email"
                  label="Email Address"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                  disabled={isSubmitting}
                />
                <TextField
                  type="password"
                  name="password"
                  label="Password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                  disabled={isSubmitting}
                />
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      color="primary"
                      defaultChecked={rememberMeChecked}
                      onChange={rememberMeChangeHandler}
                    />
                  }
                  label="Remember me"
                /> */}
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting}
                    className={classes.signInButton}
                  >
                    Sign in
                  </Button>
                  <Button
                    component={Link}
                    to="/auth/forgot-password"
                    className={classes.forgotButton}
                    disabled={isSubmitting}
                  >
                    Forgot password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Wrapper>
  );
}

export default SignIn;
