import styled from "styled-components/macro";
const Badge = styled.span`
  font-size: 11px;
  background-color: ${(props) => props.color};
  color: white;
  border-radius: 8px;
  padding: 4px 8px;
  text-align: center;
`;

function PartnerStatus({ partnerStatusId, isBadge }) {
  let statusName, color;

  if (partnerStatusId === 0 || partnerStatusId === 1) {
    statusName = "Open";
    color = "#54AAD1";
  } else if (partnerStatusId === 2) {
    statusName = "Accepted";
    color = "#76C893";
  } else if (partnerStatusId === 3) {
    statusName = "Rejected";
    color = "#EF233C";
  }
  if (isBadge) {
    return <Badge color={color}>{statusName}</Badge>;
  } else {
    return <strong style={{ color: color }}>{statusName}</strong>;
  }
}

export default PartnerStatus;
