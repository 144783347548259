import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import logo from "../../vendor/logo.png";

const useStyles = makeStyles((theme) => ({
  signInButton: {
    border: "2px solid  #01bcf3",
    color: "#01bcf3",
    "&:hover": { backgroundColor: "#e2f8ff" },
  },
}));

export const SignInButton = () => {
  const { instance } = useMsal();
  const classes = useStyles();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };
  return (
    <div variant="secondary" className="ml-auto" drop="left">
      <div as="button" onClick={() => handleLogin()}>
        <Button className={classes.signInButton} variant="outlined">
          <img src={logo} alt="" />
          Login with Microsoft Office365 Account
        </Button>
      </div>
    </div>
  );
};
