import jwt_decode from "jwt-decode";
import * as types from "../../constants";
import {
  signIn as authSignIn,
  signUp as authSignUp,
  resetPassword as authResetPassword,
} from "../../services/authService";

export function signIn(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });
    return authSignIn(credentials)
      .then((response) => {
        if (response.isSuccess) {
          const decoded = jwt_decode(response.data.auth_token);
          dispatch({
            type: types.AUTH_SIGN_IN_SUCCESS,
            email: decoded.sub,
            id: decoded.id,
            rol: decoded.rol,
            pid: decoded.sid,
            token: response.data.auth_token,
            message: response.message,
            isSuccess: response.isSuccess,
            firstName: decoded.given_name,
            lastName: decoded.family_name,
            companyName: decoded.name,
            desc: decoded.role_desc,
            exp: decoded.exp,
            companyLogo: decoded.website,
          });
          localStorage.setItem("token", response.data.auth_token);
        } else {
          dispatch({
            type: types.AUTH_SIGN_IN_FAILED,
            message: response.message,
            isSuccess: response.isSuccess,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}

export function signUp(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUp(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
          email: credentials.email,
          website: credentials.website,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function signOut() {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
      message: "",
    });
  };
}

export function resetPassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}
