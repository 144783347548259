import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
// import PartnerRequestsTable from "../../components/tables/PartnerRequestsTable";
import QuickFilteringGrid from "../../components/tables/QuickFilteringGrid";

import { Helmet } from "react-helmet-async";
import Loader from "../../components/Loader";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import api from "../../api/api";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

function PartnerRequests() {
  const partner = useSelector((state) => state.partnerReducer);
  const auth = useSelector((state) => state.authReducer);

  const { partnerStatus } = partner;

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    api

      .get("api/Partner/GetPartnerRequests?v=1.0", {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setPartners(response.data.data);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [partnerStatus]);

  if (isLoading) return <Loader />;
  if (error) return <div style={{ color: "red" }}>{error}</div>;

  return (
    <React.Fragment>
      <Helmet title="PartnerRequests" />

      <Typography variant="h3" gutterBottom display="inline">
        Partner Requests
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Pages</Typography>
        <Typography>Partner Requests</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {/* <PartnerRequestsTable partners={partners} /> */}
      <QuickFilteringGrid partners={partners} />
      {/* <Grid container spacing={6}>
        <Grid item xs={12} lg={4} xl={3}></Grid>
      </Grid> */}
    </React.Fragment>
  );
}

export default PartnerRequests;
