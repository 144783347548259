import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

function AuthGuard({ children }) {
  const auth = useSelector((state) => state.authReducer);

  if (!auth?.user?.token) {
    return <Redirect to="/" />;
  }

  return children;
}

export default AuthGuard;
