import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";

import { dashboardLayoutRoutes, authLayoutRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import { useSelector, useDispatch } from "react-redux";
import * as types from "../constants";

const childRoutes = (Layout, routes) =>
  routes.map(({ component: Component, guard, children, path }, index) => {
    const Guard = guard || React.Fragment;

    return children ? (
      children.map((element, index) => {
        const Guard = element.guard || React.Fragment;
        const ElementComponent = element.component || React.Fragment;

        return (
          <Route
            key={index}
            path={element.path}
            exact
            render={(props) => (
              <Layout>
                <Guard>
                  <ElementComponent {...props} />
                </Guard>
              </Layout>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Guard>
              <Component {...props} />
            </Guard>
          </Layout>
        )}
      />
    ) : null;
  });

const Routes = () => {
  const auth = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  const willMount = useRef(true);

  if (willMount.current) {
    const token = localStorage.getItem("token");
    let decoded;

    if (token) {
      decoded = jwt_decode(token);
      if (!auth.user?.token) {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          email: decoded.sub,
          id: decoded.id,
          rol: decoded.rol,
          pid: decoded.sid,
          token,
          firstName: decoded.given_name,
          lastName: decoded.family_name,
          companyName: decoded.name,
          desc: decoded.role_desc,
          exp: decoded.exp,
          companyLogo: decoded.website,
        });
      }
    }

    willMount.current = false;
  }

  return (
    <Router>
      <Switch>
        {childRoutes(AuthLayout, authLayoutRoutes)}
        {childRoutes(
          DashboardLayout,
          auth?.user?.rol
            ? dashboardLayoutRoutes.filter((route) =>
                route.permission.includes(auth?.user?.rol)
              )
            : dashboardLayoutRoutes
        )}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
