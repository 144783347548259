import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import api from "../../api/api";
import Loader from "../../components/Loader";
import PermissionTable from "../../components/tables/PermissionTable";
import LoopIcon from "@material-ui/icons/Loop";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Text = styled.span`
  font-weight: bold;
`;

const GridItem = styled(Grid)`
  text-align: right;
  ${(props) => props.theme.breakpoints.down("md")} {
    text-align: left;
  }
`;

const StyledTextField = styled(TextField)`
  width: 280px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const useStyles = makeStyles((theme) => ({
  permissionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 20px",
  },
  permissionContent: {
    width: "100%",
  },

  notesContainer: {
    display: "flex",
  },

  permissionTable: {
    marginTop: "10px",
    height: "450px",
    "@media (max-width: 600px)": {
      marginTop: "50px",
    },
  },

  dialogTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px 0  0",
  },

  cancelIcon: {
    cursor: "pointer",
    "&:hover": {
      opacity: "0.6",
    },
  },
  cancelButton: {
    background: "#dbdbdb",
    "&:hover": {
      background: "#c4c4c4",
    },
  },
  confirmButton: {
    color: "white",
    marginRight: "5px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },

  icon: {
    height: "25px",
    width: "25px",
    color: "#747575",
    borderRadius: "3px",
    cursor: "pointer",
    backgroundColor: "#e8e8e8",
    padding: "4px",
    boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.3)",
    "&:last-child": {
      marginLeft: "5px",
    },
    "&:hover": {
      backgroundColor: "#dbdbdb",
    },
  },
}));

function PermissionPage({ selectedApp }) {
  const [permission, setPermission] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [newAppSecret, setNewAppSecret] = useState("");
  const [oldAppSecret, setOldAppSecret] = useState("");
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notes, setNotes] = useState("");

  const [error, setError] = useState(null);

  const auth = useSelector((state) => state.authReducer);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    setIsLoading(!isLoading);
    setNewAppSecret("");

    setOldAppSecret(
      newAppSecret ? oldAppSecret : permission?.applicationSecret
    );
    setSelectedId(event.target.value);
    setIsChange(true);
  };

  const handleCloseWarningMessage = () => {
    setShowWarningMessage(false);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleConfirm = () => {
    api
      .put(
        `api/PartnerApplication/${permission?.applicationId}/versions/RegenerateSecretkey/${permission.id}?v=1.0`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setNewAppSecret(response.data.data);
          setOldAppSecret(response.data.data);
          handleCloseWarningMessage();
          enqueueSnackbar("Application Secret regenerated", {
            variant: "success",
            autoHideDuration: 3000,
          });
        }
      });
  };

  const handleSaveText = () => {
    api
      .put(
        `api/PartnerApplication/${permission?.applicationId}/versions/UpdateNotes/${permission.id}?v=1.0&notes=${notes}`,
        {
          notes: "selam",
        },
        {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Notes updated", {
            variant: "success",
            autoHideDuration: 3000,
          });
        }
      });
  };

  useEffect(() => {
    api
      .get(
        `api/PartnerApplication/${selectedApp?.appId}/versions/${
          selectedId
            ? selectedId
            : selectedApp?.versions[selectedApp?.versions.length - 1].id
        }?v=1.0`,
        {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setPermission(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  }, [selectedId]);

  function appId() {
    if (isChange) return permission?.applicationId;
    else return selectedApp?.applicationId;
  }

  function applicationSecret() {
    if (newAppSecret) {
      return newAppSecret;
    } else {
      if (!isChange) {
        return selectedApp?.versions[selectedApp?.versions.length - 1]
          .applicationSecret;
      } else {
        return isLoading ? oldAppSecret : permission?.applicationSecret;
      }
    }
  }

  function copyText(entryText) {
    navigator.clipboard.writeText(entryText);
  }

  if (error) return <div style={{ color: "red" }}>{error}</div>;
  return (
    <>
      <Dialog
        open={showWarningMessage}
        onClose={handleCloseWarningMessage}
        maxWidth="xs"
        fullWidth
      >
        <Box className={classes.dialogTitleContainer}>
          <DialogTitle className={classes.dialogTitle}>
            Confirmation
          </DialogTitle>
          <CloseIcon
            onClick={handleCloseWarningMessage}
            className={classes.cancelIcon}
          />
        </Box>
        <DialogContent>
          <Typography variant="body1" component="p">
            Application Secret will be reset. The old key will be invalid. Are
            you sure you want to continue?
          </Typography>
          <Box className={classes.buttons}>
            <Button
              type="submit"
              mr={2}
              variant="contained"
              size="small"
              className={classes.confirmButton}
              onClick={handleConfirm}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              className={classes.cancelButton}
              size="small"
              onClick={handleCloseWarningMessage}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Box className={classes.permissionContainer}>
        <Box className={classes.permissionContent}>
          <Grid container spacing={4}>
            <Grid item md={8} xs={12}>
              <form noValidate>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justify="center"
                >
                  <GridItem item md={3} xs={12}>
                    <Text> Versions :</Text>
                  </GridItem>
                  <Grid item md={9} xs={12}>
                    <Select
                      autoFocus
                      onChange={handleChange}
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                      defaultValue={
                        selectedApp?.versions[selectedApp?.versions.length - 1]
                          .id
                      }
                    >
                      {selectedApp?.versions.map((version) => (
                        <MenuItem key={version.id} value={version.id}>
                          {version.version}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <GridItem item md={3} xs={12}>
                    <Text> Application Id :</Text>
                  </GridItem>
                  <Grid item md={9} xs={12}>
                    <StyledTextField
                      name="appId"
                      variant="outlined"
                      value={appId()}
                      disabled
                      inputProps={{
                        style: {
                          padding: 6,
                        },
                      }}
                    />
                  </Grid>
                  <GridItem item md={3} xs={12}>
                    <Text> Application Secret :</Text>
                  </GridItem>
                  <Grid item md={6} xs={12}>
                    <StyledTextField
                      name="appSecret"
                      variant="outlined"
                      value={applicationSecret()}
                      disabled
                      inputProps={{
                        style: {
                          padding: 6,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Buttons>
                      <Tooltip title="Copy">
                        <FileCopyIcon
                          onClick={() => {
                            copyText(
                              newAppSecret
                                ? newAppSecret
                                : permission?.applicationSecret
                            );
                            enqueueSnackbar("Copied", {
                              variant: "info",
                              autoHideDuration: 3000,
                            });
                          }}
                          className={classes.icon}
                        />
                      </Tooltip>
                      {!auth.user?.rol.startsWith("Bromcom") && (
                        <Tooltip title="Regenerate">
                          <LoopIcon
                            onClick={() => {
                              setShowWarningMessage(true);
                            }}
                            className={classes.icon}
                          />
                        </Tooltip>
                      )}
                    </Buttons>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box className={classes.notesContainer}>
                <TextField
                  name="Notes"
                  defaultValue={permission?.notes}
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder="Notes"
                  onChange={handleNotesChange}
                  spellCheck={false}
                />
                <Tooltip title="Save">
                  <SaveIcon onClick={handleSaveText} className={classes.icon} />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>

          <Box className={classes.permissionTable}>
            {isLoading && <Loader />}
            {!isLoading && <PermissionTable permissions={permission} />}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default PermissionPage;
