import styled from "styled-components/macro";
import { Box, Dialog, DialogContent, IconButton } from "@material-ui/core/";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme as createTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => ({
    paper: { minHeight: "630px" },
    dialogTitleContainer: {
      display: "flex",
      justifyContent: "flex-end",
      MinHeight: "85px",
    },
    closeIcon: {
      cursor: "pointer",
      float: "right",
      marginTop: "5px",
      width: "20px",
    },
  }),
  { defaultTheme }
);

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-tap-highlight-color: transparent;
`;

export default function CustomDialog({ open, handleClose, children }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby="simple-dialog-title"
      aria-describedby="simple-dialog-description"
      onClose={handleClose}
      BackdropComponent={Backdrop}
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <Box className={classes.dialogTitleContainer}>
        <IconButton onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </Box>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
