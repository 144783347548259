import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { signIn } from "../../redux/actions/authActions";
import AppLogo from "../../components/AppLogo";

import {
  Box,
  Button,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const useStyles = makeStyles((theme) => ({
  gridItem: {
    minHeight: "400px",
    [theme.breakpoints.down(768)]: {
      minHeight: "300px",
    },
  },
  signInButton: {
    height: "50px",
    fontSize: "16px",
    color: "white",
    backgroundColor: "#1D88E4",
    "&:hover": { backgroundColor: "#176dea" },
  },
  signUpButton: {
    width: "100px",
    fontSize: "15px",
    color: "white",
    marginBottom: "40px",
    backgroundColor: "#1d88E4",
    "&:hover": { backgroundColor: "#176dea" },
  },
}));
const Wrapper = styled.div`
  position: absolute;

  width: 900px;
  padding: ${(props) => props.theme.spacing(6)}px;

  @media (max-width: 768px) {
    width: 100%;
    top: 0;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

const Title = styled.h4`
  font-size: 20px;
  color: #212529;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;
const Border = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e9ecef;
  margin: 10px 0 25px 0;
`;

const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
`;
const Text = styled.p`
  font-size: 17px;
`;

const FormContainer = styled.div``;

const RightSideContainer = styled.div`
  padding-left: 15px;
  margin-left: 55px;
  border-left: 1px solid #e0e0e0;

  @media (max-width: 768px) {
    margin-left: 0;
    border-left: none;
  }
`;

const ForgotPasswordLink = styled(Link)`
  font-size: 17px;
  color: #6c757d;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

function HomePage() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const auth = useSelector((state) => state.authReducer);
  return (
    <Wrapper>
      <LogoContainer>
        <AppLogo />
      </LogoContainer>
      <Grid container alignItems="center" justify="center" spacing={10}>
        <Grid item sm={6} xs={12} className={classes.gridItem}>
          <Title> Log in to Bromcom Partner Portal</Title>
          <Border />

          <Formik
            initialValues={{
              email: "",
              password: "",
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await dispatch(
                  signIn({
                    username: values.email,
                    password: values.password,
                    type: "partner",
                  })
                );
              } catch (error) {
                const message = error.message || "Something went wrong";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                {!auth?.isSuccess && auth?.message === "Wrong Password" && (
                  <Alert mt={2} mb={1} severity="warning">
                    The password you entered is incorrect. Please try again.
                  </Alert>
                )}
                {!auth?.isSuccess && auth?.message === "User Not Exist" && (
                  <Alert mt={2} mb={1} severity="warning">
                    No users found with this email address. Please check your
                    email address.
                  </Alert>
                )}
                {!auth?.isSuccess && auth?.message === "User Not Active" && (
                  <Alert mt={2} mb={1} severity="warning">
                    The account with this email is deactivated. Contact to{" "}
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location = "mailto:Partner.Portal@bromcom.com";
                        e.preventDefault();
                      }}
                    >
                      Partner.Portal@bromcom.com
                    </Link>
                  </Alert>
                )}
                <TextField
                  type="email"
                  name="email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  variant="outlined"
                  placeholder="Email"
                />
                <TextField
                  type="password"
                  name="password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                  disabled={isSubmitting}
                  variant="outlined"
                  placeholder="Password"
                />

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting}
                    className={classes.signInButton}
                  >
                    Sign in
                  </Button>
                  <ForgotPasswordContainer>
                    <ForgotPasswordLink to="/auth/forgot-password">
                      Forgot your password?
                    </ForgotPasswordLink>
                  </ForgotPasswordContainer>
                </Box>
              </form>
            )}
          </Formik>
        </Grid>
        <Grid item sm={6} xs={12} className={classes.gridItem}>
          <RightSideContainer>
            <Title> Not Registered?</Title>
            <Border />
            <Text>
              Create an account to get access to Bromcom API, FAQ, examples and
              connect with Bromcom schools.
            </Text>
            <Text>Click the button below to get started.</Text>
            <Button
              fullWidth
              variant="contained"
              component={Link}
              className={classes.signUpButton}
              to="/auth/sign-up"
            >
              Sign up
            </Button>

            <Text>
              Need help? Send us an email at Partner.Portal@bromcom.com
            </Text>
          </RightSideContainer>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default HomePage;
