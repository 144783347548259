import * as types from "../../constants";

export function addScope(name, scope) {
  return {
    type: types.SCOPE_ADD,
    name,
    scope: scope,
  };
}

export function resetScopes() {
  return {
    type: types.SCOPES_RESET,
    scopes: [],
  };
}

export function setScopes(scope) {
  return {
    type: types.SCOPES_RESET,
    scopes: scope,
  };
}
