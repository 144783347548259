import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  FormControl,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { Add as AddIcon } from "@material-ui/icons";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import api from "../../api/api";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);

const useStyles = makeStyles((theme) => ({
  text: {
    width: "500px",
  },
  buttons: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-end",
  },
  createMemberButton: {
    color: "white",
    padding: "8px 20px",
    background: "#7FB741",
    "&:hover": {
      background: "#8AC747",
    },
  },
  updateButton: {
    color: "white",
    padding: "8px 20px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },
  cancelButton: {
    color: "white",
    padding: "8px 20px",
    background: "#c71414",
    "&:hover": {
      background: "#ff0000",
    },
  },
}));

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string()
    .email()
    .matches(
      /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!outlook.com)([\w-]+\.)+[\w-]{2,4})?$/,
      "free email services will not be allowed."
    )
    .required("Required"),
  role: Yup.string().required("Required"),
});

function MemberDetail() {
  const history = useHistory();
  const { userId } = useParams();
  const [member, setMember] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const auth = useSelector((state) => state.authReducer);

  const handleCancelMember = () => {
    history.push("/manageteam");
  };

  useEffect(() => {
    api
      .get(`api/User/${userId}?v=1.0`, {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setMember(response.data.data);
          setIsLoading(false);
        } else {
          if (response.data.errorCode === 404) {
            history.push("/404");
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <div style={{ color: "red" }}>{error}</div>;
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        firstName: member?.firstName,
        lastName: member?.lastName,
        email: member?.email,
        role: member?.idsRole[0],
        status: member?.status,
      }}
      validationSchema={validationSchema}
      onSubmit={(
        values,
        { setErrors, resetForm, setStatus, setSubmitting }
      ) => {
        api
          .put(
            `api/User/Update/${userId}?v=1.0`,
            {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              idsRole: [values.role],
              status: values.status,
              apiKey: "",
            },
            {
              headers: {
                Authorization: `Bearer ${auth.user?.token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.isSuccess) {
              setSubmitting(false);
              enqueueSnackbar("Member updated successfully", {
                variant: "success",
                autoHideDuration: 3000,
              });
              handleCancelMember();
            } else {
              if (response.data.errorMessage === "User Exist") {
                setSubmitting(false);
                enqueueSnackbar("User already exits.", {
                  variant: "warning",
                  autoHideDuration: 3000,
                });
              }
            }
          })
          .catch((error) => {
            setStatus({ sent: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        isSubmitting,
      }) => (
        <>
          <Helmet title="ManageTeam" />

          <Grid justify="space-between" container spacing={4}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Manage Team
              </Typography>

              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Typography>Pages</Typography>
                <Typography>Manage Team</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/createmember")}
                className={classes.createMemberButton}
              >
                <AddIcon />
                Create New Member
              </Button>
            </Grid>
          </Grid>
          <Divider my={6} />
          <Form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card mb={6}>
                  <CardContent>
                    <TextField
                      name="firstName"
                      defaultValue={values.firstName}
                      label="First Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                      my={2}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                    <TextField
                      name="lastName"
                      defaultValue={values.lastName}
                      label="Last Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                      my={2}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                    <TextField
                      name="email"
                      defaultValue={values.email}
                      label="Email"
                      variant="outlined"
                      type="email"
                      fullWidth
                      my={2}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                    {values.role === 4 ? (
                      <TextField
                        name="role"
                        defaultValue="Account Owner"
                        variant="outlined"
                        type="role"
                        fullWidth
                        my={2}
                        disabled
                      />
                    ) : (
                      <TextField
                        name="role"
                        select
                        label="Roles"
                        variant="outlined"
                        fullWidth
                        defaultValue={values?.role}
                        value={values?.role ?? ""}
                        my={2}
                        error={Boolean(touched.role && errors.role)}
                        helperText={touched.role && errors.role}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        {member?.roles.map((role) => {
                          if (role.id === 4) return null;
                          else {
                            return (
                              <MenuItem key={role.id} value={role.id}>
                                {role.description}
                              </MenuItem>
                            );
                          }
                        })}
                      </TextField>
                    )}

                    <FormControl component="fieldset">
                      <FormControlLabel
                        disabled={
                          isSubmitting || values.role === 4 ? true : false
                        }
                        control={
                          <Switch
                            defaultChecked={member?.status}
                            onChange={handleChange}
                            name="status"
                          />
                        }
                        label={values?.status ? "Active" : "Inactive"}
                      />
                    </FormControl>

                    <Box className={classes.buttons}>
                      <Button
                        type="submit"
                        mr={2}
                        variant="contained"
                        className={classes.updateButton}
                      >
                        {!isSubmitting && "Update"}
                        {isSubmitting && (
                          <CircularProgress
                            size={18}
                            style={{ color: "white" }}
                          />
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={handleCancelMember}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default MemberDetail;
