import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import api from "../../api/api";
import Loader from "../../components/Loader";
import RegisteredSchoolsTable from "../../components/tables/RegisteredSchoolsTable";

const useStyles = makeStyles((theme) => ({
  registeredSchoolContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
    minHeight: "550px",
  },
}));

function RegisteredSchools({ selectedApp }) {
  const [schools, setSchools] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const auth = useSelector((state) => state.authReducer);

  const classes = useStyles();

  useEffect(() => {
    api
      .get(`api/RegisteredSchool/${selectedApp?.appId}/List?v=1.0`, {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setSchools(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  }, []);

  if (error) return <div style={{ color: "red" }}>{error}</div>;

  return (
    <Box className={classes.registeredSchoolContainer}>
      {isLoading && <Loader />}
      {!isLoading && <RegisteredSchoolsTable schools={schools} />}
    </Box>
  );
}

export default RegisteredSchools;
