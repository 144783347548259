import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
import Moment from "react-moment";

import {
  Breadcrumbs,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  IconButton,
  Typography,
} from "@material-ui/core/";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";

import api from "../../api/api";

import Loader from "../../components/Loader";
import axios from "axios";
import PartnerAccountsAppTable from "../../components/tables/PartnerAccountsAppTable";
import { useSnackbar } from "notistack";

const Divider = styled(MuiDivider)(spacing);

const InnerBox = styled.div`
  text-align: center;
  display: flex;
  justify-content: flex-start;
`;

const useStyles = makeStyles((theme) => ({
  detailHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailTitle: {
    marginBottom: "15px",
  },
  detailContent: {
    backgroundColor: "#fff",
    marginTop: theme.spacing(1),
    borderRadius: "5px",
    padding: "20px",
    border: "1px solid #e0e0e0",
  },
  deactiveButton: {
    cursor: "pointer",
    color: "white",
    padding: "0 10px",
    height: "30px",
    backgroundColor: "#c71414",
    "&:hover": {
      backgroundColor: "#ff0000",
    },
  },
  activeButton: {
    cursor: "pointer",
    padding: "0 10px",
    color: "white",
    width: "60px",
    height: "30px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },
  appTable: {
    marginTop: "30px",
  },
  buttons: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px 0  0",
  },

  cancelIcon: {
    cursor: "pointer",
    "&:hover": {
      opacity: "0.6",
    },
  },
  deactiveButtonInDialog: {
    color: "white",
    marginRight: "5px",
    backgroundColor: "#c71414",
    "&:hover": {
      backgroundColor: "#ff0000",
    },
  },
  activeButtonInDialog: {
    color: "white",
    marginRight: "5px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },
  cancelButton: {
    background: "#dbdbdb",
    "&:hover": {
      background: "#c4c4c4",
    },
  },
}));

export default function PartnerAccountsDetail() {
  const classes = useStyles();
  const { partnerId } = useParams();

  const [partner, setPartner] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [partnerStatus, setPartnerStatus] = useState(false);

  const auth = useSelector((state) => state.authReducer);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleChangePartnerStatus = () => {
    setPartnerStatus(!partnerStatus);
  };

  const handleShowDetail = () => {
    setShowDetail(true);
  };

  const handleCloseDetail = () => {
    setShowDetail(false);
  };

  const handleDeactivate = async () => {
    setIsLoading(true);
    api
      .put(
        `api/Partner/Deactive/${partnerId}?v=1.0`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setPartnerStatus(!partnerStatus);
          setIsLoading(false);
          setShowDetail(false);

          enqueueSnackbar("Partner deactivated.", {
            variant: "success",
            autoHideDuration: 3000,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  };

  const handleActivate = async () => {
    setIsLoading(true);
    api
      .put(
        `api/Partner/Active/${partnerId}?v=1.0`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setPartnerStatus(!partnerStatus);
          setShowDetail(false);

          enqueueSnackbar("Partner activated.", {
            variant: "success",
            autoHideDuration: 3000,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  };

  useEffect(() => {
    axios
      .all([
        api.get(`api/Partner/GetPartner/${partnerId}?v=1.0`, {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }),
        api.get(`api/PartnerApplication/List/${partnerId}?v=1.0`, {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }),
      ])
      .then(
        axios.spread(function (partner, applications) {
          if (partner.data.data) {
            setPartner({
              partner: partner.data.data,
              applications: applications.data.data,
            });
            setIsLoading(false);
          } else {
            if (partner.data.errorCode === 404) {
              history.push("/404");
            }
          }
        })
      )
      .catch((error) => console.log(error));
  }, [partnerStatus]);

  if (isLoading) return <Loader />;
  if (error) return <div style={{ color: "red" }}>{error}</div>;
  return (
    <>
      {showDetail && (
        <Dialog open={showDetail} onClose={handleCloseDetail}>
          <Box className={classes.dialogTitleContainer}>
            <DialogTitle className={classes.dialogTitle}>
              Confirmation
            </DialogTitle>
            <CloseIcon
              onClick={handleCloseDetail}
              className={classes.cancelIcon}
            />
          </Box>
          <DialogContent>
            <Typography variant="body1" component="p">
              Are you sure you want to
              {partner?.partner?.isActive ? " deactivate" : " activate"} this
              partner ?
            </Typography>
            <Box className={classes.buttons}>
              {partner?.partner?.isActive ? (
                <Button
                  type="submit"
                  mr={2}
                  variant="contained"
                  size="small"
                  className={classes.deactiveButtonInDialog}
                  onClick={handleDeactivate}
                >
                  Deactivate
                </Button>
              ) : (
                <Button
                  type="submit"
                  mr={2}
                  variant="contained"
                  size="small"
                  className={classes.activeButtonInDialog}
                  onClick={handleActivate}
                >
                  Activate
                </Button>
              )}
              <Button
                variant="contained"
                className={classes.cancelButton}
                size="small"
                onClick={handleCloseDetail}
              >
                Cancel
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      <Box>
        <Helmet title="PartnerAccounts" />

        <Typography variant="h3" gutterBottom display="inline">
          Partner Accounts
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Typography>Pages</Typography>
          <Typography>Partner Accounts</Typography>
        </Breadcrumbs>

        <Divider my={6} />
        <Box className={classes.detailHeader}>
          <IconButton onClick={() => history.push("/partner-accounts")}>
            <ArrowBackIcon />
          </IconButton>

          {partner?.partner?.isActive ? (
            <Button
              size="small"
              className={classes.deactiveButton}
              onClick={handleShowDetail}
            >
              Deactivate
            </Button>
          ) : (
            <Button
              size="small"
              className={classes.activeButton}
              onClick={handleShowDetail}
            >
              Activate
            </Button>
          )}
        </Box>

        <Box className={classes.detailContent}>
          <Typography variant="h4" className={classes.detailTitle}>
            Partner Detail
          </Typography>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
              maxWidth={200}
              minWidth={200}
            >
              Organization:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
              
            >
              {partner?.partner?.organizationName}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
              maxWidth={200}
              minWidth={200}
            >
              First Name:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.partner?.firstName}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
              maxWidth={200}
              minWidth={200}
            >
              Last Name:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.partner?.lastName}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
              maxWidth={200}
              minWidth={200}
            >
              Email:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.partner?.email}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
              maxWidth={200}
              minWidth={200}
            >
              Telephone Number :
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.partner?.phone}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
              maxWidth={200}
              minWidth={200}
            >
              Website:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.partner?.webSite}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
              maxWidth={200}
              minWidth={200}
            >
              Role:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.partner?.role}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
              maxWidth={200}
              minWidth={200}
            >
              No of Bromcom Customers:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.partner?.customerCount}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
              maxWidth={200}
              minWidth={200}
            >
              Services Provided to Bromcom Customers:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.partner?.servicesProvidedToBC}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
              maxWidth={200}
              minWidth={200}
            >
              Planned Integrations:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.partner?.plannedIntegrations}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
              maxWidth={200}
              minWidth={200}
            >
              Created Date:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            > 
             <Moment
                date={partner?.partner?.createdDate}
                format="DD/MM/YYYY HH:mm:ss"
              />
            </Box>
          </InnerBox>
          <Box className={classes.appTable}>
            {partner?.applications.length > 0 ? (
              <PartnerAccountsAppTable
                applications={partner?.applications}
                height={450}
                changeStatus={handleChangePartnerStatus}
              />
            ) : (
              <PartnerAccountsAppTable height={200} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
