import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormControlLabel,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import api from "../../api/api";
import Loader from "../../components/Loader";
import CloseIcon from "@material-ui/icons/Close";

import { MemoizedNewApiScopesTable } from "../../components/tables/NewApiScopesTable";

import { useSnackbar } from "notistack";

const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Logo = styled.div`
  position: relative;
  border: 2px dashed #ccc;
  width: 200px;
  height: 165px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 3px;

  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100px;
    height: 80px;
  }

  span {
    font-size: 20px;
    font-weight: bold;
  }

  &:before {
    opacity: 0;
    content: "upload \n logo";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #4d5658;
    font-size: 18px;
    font-weight: bold;
    background: rgb(223, 225, 229, 0.9);
    z-index: 2;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    ${(props) => props.theme.breakpoints.down("lg")} {
      font-size: 14px;
    }
  }

  &:hover {
    &:before {
      opacity: ${(props) => props.opacity};
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-end",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  InputLabel: {
    opacity: 0.6,
    padding: " 5px 10px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
  },
  attachedImage: {
    height: "70px",
    width: "70px",
    objectFit: "cover",
    border: "2px solid #ddd",
    cursor: "pointer",
    marginRight: "5px",
  },
  attachedFiles: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    margin: "0 4px",
  },

  uploadButton: {
    color: "white",
    padding: "5px 10px",
  },
  cancelIcon: {
    cursor: "pointer",
    position: "absolute",
    top: "-10px",
    right: "-5px",
    transform: "scale(.7)",
    color: "#c71414",
    "&:hover": {
      color: "#ff0000",
    },
  },
  formGroup: {
    border: "1px solid #ddd",
    padding: "10px",
    marginTop: "10px",
    borderRadius: "5px",
  },
  formLabel: {
    marginBottom: "10px",
  },

  updateButton: {
    color: "white",
    padding: "8px 20px",
    background: "#FF911D",
    "&:hover": {
      background: "#ff880a",
    },
  },
  cancelButton: {
    color: "white",
    padding: "8px 20px",
    background: "#c71414",
    "&:hover": {
      background: "#ff0000",
    },
  },
  container: {
    minHeight: "200px",
    padding: "30px 20px",
  },
  alertMessage: {
    display: "block",
    minWidth: "250px",
  },
  cancelAlertIconContainer: {
    position: "relative",
  },
  cancelAlertIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.6",
    },
  },
  dialogTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px 0  0",
  },
  detailContainer: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cancelAlertButton: {
    background: "#dbdbdb",
    "&:hover": {
      background: "#c4c4c4",
    },
  },
  OkAlertButton: {
    color: "white",
    marginRight: "5px",
    background: "#7FB741",
    "&:hover": {
      background: "#8AC747",
    },
  },
}));

const validationSchema = Yup.object().shape({
  appDescription: Yup.string().required("Required"),
});

function ApplicationDetail() {
  const [app, setApp] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showInfo, setShowInfo] = useState({ isShow: false, message: "" });
  const selectedScopes = useSelector((state) => state.scopeReducer);

  const history = useHistory();

  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const [attachedFiles, setAttachedFiles] = useState([]);

  const formRef = useRef();

  const classes = useStyles();

  const auth = useSelector((state) => state.authReducer);

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const handleUpdateClick = () => {
    const selectedOldScopes = app.scopes.filter(
      (row) => !!row.get || !!row.post || !!row.put || !!row.delete
    );
    if (
      selectedScopes.scopes.length !== 0 &&
      selectedScopes.scopes.length > selectedOldScopes.length
    ) {
      setShowInfo({
        isShow: true,
        message:
          "You have increased your application (" +
          app.name +
          ") data scope, and this will result increase of the version number, which your customers need to visit 'Partner Applications' within Bromcom MIS, and approve the new version to be able to retrieve the use scope. Until they approve the new version, you will continue to retrieve the installed version's data scope. This operation is irreversible. Do you want to continue?",
      });
    } else if (
      selectedScopes.scopes.length !== 0 &&
      selectedScopes.scopes.length < selectedOldScopes.length
    ) {
      setShowInfo({
        isShow: true,
        message:
          "You have decreased your application (" +
          app.name +
          ") data scope, and this scope reduction does not require any version change, but it is irreversible operation. Do you want to continue?",
      });
    } else {
      let result = false;
      selectedScopes.scopes.forEach((scope) => {
        selectedOldScopes.forEach((oldScope) => {
          if (scope.name !== oldScope.name) {
            result = true;
          } else {
            result = false;
          }
        });
      });
      if (result) {
        setShowInfo({
          isShow: true,
          message:
            "You have changed your application (" +
            app.name +
            ") data scope, and this will result increase of the version number, which your customers need to visit 'Partner Applications' within Bromcom MIS, and approve the new version to be able to retrieve the use scope. Until they approve the new version, you will continue to retrieve the installed version's data scope. This operation is irreversible. Do you want to continue?",
        });
      } else {
        handleFormSubmit();
      }
    }
  };

  const handleCloseInfo = () => {
    setShowInfo(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    if (fileSize > 102400) {
      enqueueSnackbar("File size is too big! Please upload a smaller image", {
        variant: "warning",
        autoHideDuration: 3000,
      });
    } else {
      setAttachedFiles([...e.target.files]);
    }
    e.target.value = null;
  };

  const handleCancelApplication = () => {
    history.push("/applications");
  };

  useEffect(() => {
    api
      .get(`api/PartnerApplication/${id}?v=1.0`, {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setApp(response.data.data);
          setIsLoading(false);
        } else {
          if (response.data.errorCode === 404) {
            history.push("/404");
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <div style={{ color: "red" }}>{error}</div>;

  return (
    <Formik
      initialValues={{
        appDescription: app?.description,
        isActive: app?.isActive,
      }}
      validationSchema={validationSchema}
      onSubmit={(
        values,
        { setErrors, resetForm, setStatus, setSubmitting }
      ) => {
        handleCloseInfo();
        const updateError = (error) => {
          setStatus({ sent: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        };

        const updateSubmit = () => {
          if (selectedScopes.scopes.length === 0) {
            enqueueSnackbar("Please select at least one endpoint", {
              variant: "warning",
              autoHideDuration: 3000,
            });

            setStatus({ sent: false });
            setSubmitting(false);
          } else {
            return api
              .put(
                `api/PartnerApplication/Update/${app?.id}?v=1.0`,
                {
                  name: app?.name,
                  logoPath: aFiles.length > 0 ? aFiles[0].path : app?.logoPath,
                  description: values.appDescription,
                  scopes: selectedScopes.scopes,
                  statusId: values.isActive ? 1 : 2,
                  applicationVersionId: app?.applicationVersionId,
                },
                {
                  headers: {
                    Authorization: `Bearer ${auth.user?.token}`,
                  },
                }
              )
              .then((response) => {
                if (response.status === 200) {
                  setStatus({ sent: false });
                  setSubmitting(false);
                  history.push("/applications");
                  enqueueSnackbar("Application updated successfully", {
                    variant: "success",
                    autoHideDuration: 3000,
                  });
                }
              });
          }
        };

        let aFiles = [];
        let promises = [];
        attachedFiles.forEach((file) => {
          const formData = new FormData();
          formData.append("File", file);
          promises.push(
            api
              .post("api/File/upload?v=1.0", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${auth.user?.token}`,
                },
              })
              .then((response) => {
                aFiles.push({
                  id: response.data.data.id,
                  path: response.data.data.path,
                  mimeType: file.type,
                });
              })
          );
        });

        if (promises.length > 0) {
          Promise.all(promises).then(updateSubmit).catch(updateError);
        } else {
          updateSubmit().catch(updateError);
        }
      }}
      innerRef={formRef}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        isSubmitting,
      }) => (
        <>
          {showInfo?.isShow && (
            <Dialog open={showInfo} onClose={handleCloseInfo}>
              <Box className={classes.dialogTitleContainer}>
                <DialogTitle>Confirmation</DialogTitle>
                <CloseIcon
                  onClick={handleCloseInfo}
                  className={classes.cancelAlertIcon}
                />
              </Box>
              <DialogContent>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.alertMessage}
                >
                  {showInfo?.message}
                </Typography>
                <Box className={classes.buttons}>
                  <Button
                    mr={2}
                    variant="contained"
                    size="small"
                    className={classes.OkAlertButton}
                    onClick={handleFormSubmit}
                  >
                    Ok
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.cancelAlertButton}
                    size="small"
                    onClick={handleCloseInfo}
                  >
                    Cancel
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          )}

          <Form noValidate onSubmit={handleSubmit}>
            <Card mb={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item md={9} xs={12}>
                    <TextField
                      name="appName"
                      label="Application Name"
                      variant="outlined"
                      type="text"
                      defaultValue={app?.name}
                      fullWidth
                      my={2}
                      disabled
                      error={Boolean(touched.appName && errors.appName)}
                      helperText={touched.appName && errors.appName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <TextField
                      name="appDescription"
                      label="Application Description"
                      variant="outlined"
                      type="text"
                      defaultValue={values.appDescription}
                      multiline
                      rows={4}
                      fullWidth
                      my={2}
                      error={Boolean(
                        touched.appDescription && errors.appDescription
                      )}
                      helperText={
                        touched.appDescription && errors.appDescription
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Box className={classes.imageContainer}>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="raised-button-file"
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                      />
                      <label htmlFor="raised-button-file">
                        <Logo
                          opacity={
                            app?.logoPath || attachedFiles.length > 0 ? 1 : 0
                          }
                        >
                          {!app?.logoPath ? (
                            attachedFiles.length === 0 ? (
                              <>
                                <span>INSERT</span>
                                <span>LOGO</span>
                                <span>HERE</span>
                              </>
                            ) : (
                              <Image
                                src={URL.createObjectURL(attachedFiles[0])}
                                alt="Application Logo"
                              />
                            )
                          ) : attachedFiles.length > 0 ? (
                            <Image
                              src={URL.createObjectURL(attachedFiles[0])}
                              alt="Application Logo"
                            />
                          ) : (
                            <Image src={app?.logoPath} alt="Application Logo" />
                          )}
                        </Logo>
                      </label>
                    </Box>
                  </Grid>
                  <Box marginY={2} p={2}>
                    <MemoizedNewApiScopesTable scopes={app?.scopes} />
                  </Box>
                  <Grid container>
                    <Grid item md={6} xs={12}></Grid>
                    <Grid item md={6} xs={12}>
                      <Box className={classes.buttons}>
                        <FormControlLabel
                          name="isActive"
                          control={<Checkbox checked={values.isActive} />}
                          label="Publish"
                          defaultValue={values.isActive}
                          onChange={handleChange}
                          disabled={isSubmitting}
                        />
                        <Button
                          mr={2}
                          variant="contained"
                          className={classes.updateButton}
                          disabled={isSubmitting}
                          onClick={handleUpdateClick}
                        >
                          {!isSubmitting && "Update"}
                          {isSubmitting && (
                            <CircularProgress
                              size={18}
                              style={{ color: "white" }}
                            />
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.cancelButton}
                          onClick={handleCancelApplication}
                          disabled={isSubmitting}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default ApplicationDetail;
