import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
  Breadcrumbs,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  IconButton,
  FormControl,
  FormControlLabel,
  Grid,
  Select,
  Switch,
  MenuItem,
  Typography,
} from "@material-ui/core/";

import { red } from "@material-ui/core/colors";

import { Helmet } from "react-helmet-async";
import Moment from "react-moment";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import api from "../../api/api";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";
import CustomModal from "../../components/CustomModal";
import AttachedFile from "../../components/AttachedFile";
import ShowAttachedFile from "../../components/ShowAttachedFile";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { rgba } from "polished";

const QuillWrapperTop = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;
const QuillWrapperBottom = styled.div`
  .ql-editor {
    min-height: 50px;
    max-height: 300px;
  }
`;

const CardHeader = styled.div`
  width: 100%;
  padding: 16px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CardTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const CardTexts = styled.div`
  display: flex;
  flex-direction: column;
`;

const AvatarContainer = styled.div`
  ${(props) => props.theme.breakpoints.down("md")} {
    height: 80px;
  }
`;

const Avatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 16px;
  background: #f44336;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarText = styled.span`
  font-size: 18px;
  color: #f7f9fc;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  align-items: center;
  height: 100%;

  ${(props) => props.theme.breakpoints.down("md")} {
    justify-content: flex-start;
  }
`;

const SelectText = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-right: 8px;
`;

const UserSelect = styled(Select)`
  width: 180px;
`;

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },

  description: {
    minHeight: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  descriptionBottom: {
    height: "55px",
    padding: "0 5px",

    display: "flex",
    alignItems: "center",
  },
  imagesContainer: {
    height: "55px",
    padding: "0 5px",

    display: "flex",
    alignItems: "center",
  },
  attachedImage: {
    height: "30px",
    width: "70px",
    objectFit: "cover",
    border: "2px solid #ddd",
    cursor: "pointer",
    marginRight: "5px",

    "&:hover": {
      border: "2px solid #a6a6a6",
    },
  },

  commentContainer: {
    marginTop: "10px",
    border: "1px solid #ddd",
  },
  comment: {
    padding: " 5px 10px",
  },
  commentActions: {
    backgroundColor: "#f5f5f5",
    padding: "5px 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  postButton: {
    color: "white",
    padding: "0 18px",
    height: "35px",
    background: "#00b2ff",
    border: "none",
    borderRadius: "2px",
    cursor: "pointer",
    "&:hover": {
      background: "#009ce0",
    },
  },
  comments: {
    padding: "15px 0 0 10px",
  },
  singleComment: {
    padding: "10px",
    display: "flex",
    backgroundColor: "#f0f0f0",
    marginBottom: "10px",
  },
  commentAvatar: {
    height: "45px",
    width: "45px",
    color: "#a8a8a8",
  },
  singleCommentTexts: {
    padding: "2px 5px",
  },
  singleCommentTitle: {
    fontWeight: "bold",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    color: "#5392e6",
    marginRight: "10px",
  },
  singleCommentDate: {
    color: "#ababab",
  },
  singleCommentAttachedImages: {
    marginTop: "10px",
  },

  attachedFiles: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    margin: "0 4px",
  },
  cancelIcon: {
    cursor: "pointer",
    position: "absolute",
    top: "-10px",
    right: "-5px",
    transform: "scale(.7)",
    color: "#c71414",
    "&:hover": {
      color: "#ff0000",
    },
  },
  detailHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formControlContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  switch: {
    transform: "translateX(12px)",
  },
  subheader: {
    fontSize: "13px",
    color: rgba(0, 0, 0, 0.4),
  },
}));

function SubHeader({ fullName, createdDate, companyName }) {
  const classes = useStyles();
  return (
    <div className={classes.subheader}>
      Writed by {fullName} -{" "}
      <Moment date={createdDate} format="DD/MM/YYYY HH:mm:ss" /> -{" "}
      <span>{companyName}</span>
    </div>
  );
}

export default function SupportTicketDetail() {
  const history = useHistory();
  const { ticketId } = useParams();

  const classes = useStyles();

  const [attachedFiles, setAttachedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isLoadingComments, setIsLoadingComments] = useState(true);

  const [isCommentSubmitted, setIsCommentSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [supportTicket, setSupportTicket] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [showImage, setShowImage] = useState(false);

  const [editorValue, setEditorValue] = useState("");

  const [users, setUsers] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const auth = useSelector((state) => state.authReducer);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendComment = () => {
    if (editorValue) {
      let aFiles = [];
      let promises = [];
      attachedFiles.forEach((file) => {
        const formData = new FormData();
        formData.append("File", file);

        promises.push(
          api
            .post("api/File/upload?v=1.0", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${auth.user?.token}`,
              },
            })
            .then((response) => {
              setIsLoadingComments(!isLoadingComments);
              aFiles.push({
                id: response.data.data.id,
                path: response.data.data.path,
                mimeType: file.type,
              });
            })
        );
      });

      Promise.all(promises)
        .then((response) => {
          api
            .post(
              `api/SupportTicket/${supportTicket?.id}/comments/Create?v=1.0`,
              {
                description: editorValue,
                attachedFiles: aFiles,
              },
              {
                headers: {
                  Authorization: `Bearer ${auth.user?.token}`,
                },
              }
            )
            .then((response) => {
              setEditorValue("");
              setIsLoadingComments(!isLoadingComments);
              setIsCommentSubmitted(!isCommentSubmitted);
              setAttachedFiles([]);
            });
        })
        .catch((error) => {
          setIsLoadingComments(isLoadingComments);
          setError(error.message);
        });
    } else {
      enqueueSnackbar("Comment can't be empty!", {
        variant: "warning",
        autoHideDuration: 3000,
      });
    }
  };

  const handleFileChange = (e) => {
    const newFiles = Object.values(e.target.files);
    const filteredAttachedFiles = newFiles.filter((file) =>
      file.name.match(/\.(jpg|jpeg|png|doc|docx|xls|xlsx|txt|pdf)$/i)
    );

    if (attachedFiles.length > 0) {
      const newFilteredAttachedFiles = attachedFiles.concat(
        filteredAttachedFiles.filter(
          ({ name }) => !attachedFiles.find((f) => f.name === name)
        )
      );

      setAttachedFiles(newFilteredAttachedFiles);
    } else {
      setAttachedFiles(filteredAttachedFiles);
    }
    e.target.value = null;
  };
  const handleChangeSwitch = (e) => {
    if (e.target.checked) {
      api
        .put(
          `api/SupportTicket/Active/${ticketId}?v=1.0`,
          {},
          {
            headers: {
              Authorization: `Bearer ${auth.user?.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            enqueueSnackbar("Ticket opened", {
              variant: "success",
              autoHideDuration: 3000,
            });
          }
        })
        .catch((error) => {});
    } else {
      api
        .put(
          `api/SupportTicket/Deactive/${ticketId}?v=1.0`,
          {},
          {
            headers: {
              Authorization: `Bearer ${auth.user?.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            enqueueSnackbar("Ticket closed", {
              variant: "success",
              autoHideDuration: 3000,
            });
          }
        })
        .catch((error) => {});
    }
  };

  const handleCancelAttachedFile = (e) => {
    const newAttachedFiles = attachedFiles.filter((file) => file.name !== e);

    setAttachedFiles(newAttachedFiles);
  };

  const handleCloseImage = (e) => {
    setShowImage(false);
  };

  const handleUserChange = (e) => {
    api
      .put(
        `api/SupportTicket/AssigntoUser/${ticketId}?v=1.0&userId=${e.target.value}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Ticket assigned to user successfully ", {
            variant: "success",
            autoHideDuration: 3000,
          });
        }
      });
  };

  useEffect(() => {
    api
      .get(`api/SupportTicket/${ticketId}/comments/all?v=1.0`, {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setSupportTicket(response.data.data);
          setIsLoading(false);
          setIsLoadingComments(!isLoadingComments);
        } else {
          if (response.data.errorCode === 404) {
            history.push("/404");
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setIsLoadingComments(!isLoadingComments);
        setError(error.message);
      });
  }, [isCommentSubmitted]);

  useEffect(() => {
    api
      .get(`api/User?OnlySystemUsers=true&v=1.0`, {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setUsers(response.data.data);
        }
      });
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <div>{error}</div>;

  return (
    <React.Fragment>
      <Helmet title="Support Tickets" />

      <Grid justify="space-between" container spacing={4}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Support Tickets
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Pages</Typography>
            <Typography>Support Tickets</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      {showImage && (
        <CustomModal open={showImage} handleClose={handleCloseImage} bp="lg">
          {<img src={URL.createObjectURL(selectedImage)} alt={selectedImage} />}
        </CustomModal>
      )}
      <Box className={classes.detailHeader}>
        <IconButton
          onClick={() => {
            history.push("/supporttickets");
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Box className={classes.formControlContainer}>
          <span>Close</span>
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={supportTicket?.supportTicketStatusId}
                  onChange={handleChangeSwitch}
                  name="status"
                  className={classes.switch}
                />
              }
            />
          </FormControl>
          <span>Open</span>
        </Box>
      </Box>
      <Card>
        <CardHeader>
          <AvatarContainer>
            <Avatar>
              <AvatarText>{supportTicket?.companyName[0]}</AvatarText>
            </Avatar>
          </AvatarContainer>

          <Grid container>
            <Grid item md={8} xs={12}>
              <CardTexts>
                <CardTitle>{supportTicket?.title}</CardTitle>
                <SubHeader
                  fullName={supportTicket?.fullName}
                  createdDate={supportTicket?.createdDate}
                  companyName={supportTicket?.companyName}
                />
              </CardTexts>
            </Grid>
            <Grid item md={4} xs={12}>
              {auth?.user?.rol && auth?.user?.rol.startsWith("Bromcom") && (
                <SelectContainer>
                  <SelectText>Assign to :</SelectText>
                  <UserSelect
                    autoFocus
                    onChange={handleUserChange}
                    inputProps={{
                      name: "user-select",
                    }}
                    defaultValue={supportTicket?.assignedUserId > 0 ? supportTicket?.assignedUserId : 0}
                  >
                    <MenuItem key={0} value={0}>
                      <b>None</b>
                    </MenuItem>
                    {users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.firstName} {user.lastName}
                      </MenuItem>
                    ))}
                  </UserSelect>
                </SelectContainer>
              )}
            </Grid>
          </Grid>
        </CardHeader>
        <Divider />
        <CardContent>
          <Box className={classes.description}>
            <Typography variant="body2" color="textSecondary" component="p">
              {supportTicket?.description}
            </Typography>
            <Box className={classes.descriptionBottom}>
              {supportTicket?.attachedFiles?.map((file) => (
                <Box component="span" key={file.id}>
                  <ShowAttachedFile
                    file={file}
                    setOpen={setOpen}
                    selectedImage={setSelectedImage}
                  />
                </Box>
              ))}
              {open && (
                <CustomModal open={open} handleClose={handleClose} bp="lg">
                  <img src={selectedImage} alt={selectedImage} />
                </CustomModal>
              )}
            </Box>
          </Box>

          <Box className={classes.commentContainer}>
            <QuillWrapperTop>
              <ReactQuill
                theme="snow"
                value={editorValue}
                onChange={setEditorValue}
                placeholder="Type something.."
              />
            </QuillWrapperTop>

            <Box className={classes.commentActions}>
              <Box className={classes.imagesContainer}>
                <input
                  accept=".xlsx,.xls,image/*,.doc, .docx,.txt,.pdf"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                />

                <label htmlFor="raised-button-file">
                  <IconButton
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.uploadButton}
                    disabled={isLoadingComments}
                  >
                    <AttachFileIcon />
                  </IconButton>
                </label>

                {attachedFiles.map((file) => (
                  <span className={classes.attachedFiles} key={file.name}>
                    <CancelIcon
                      className={classes.cancelIcon}
                      onClick={handleCancelAttachedFile.bind(this, file?.name)}
                    />
                    <AttachedFile file={file} />
                  </span>
                ))}
              </Box>

              <button
                type="submit"
                variant="contained"
                className={classes.postButton}
                onClick={handleSendComment}
              >
                {!isLoadingComments ? (
                  "Post"
                ) : (
                  <CircularProgress size={18} style={{ color: "white" }} />
                )}
              </button>
            </Box>
          </Box>

          <Box className={classes.comments}>
            {supportTicket?.comments
              ?.slice(0)
              .reverse()
              .map((comment) => {
                return (
                  <Box className={classes.singleComment} key={comment.id}>
                    <AccountCircle className={classes.commentAvatar} />
                    <Box className={classes.singleCommentTexts}>
                      <Box>
                        <Typography
                          variant="body2"
                          className={classes.singleCommentTitle}
                        >
                          {comment?.fullName}
                        </Typography>

                        <span className={classes.singleCommentDate}>
                          <Moment
                            date={comment?.createdDate}
                            format="DD/MM/YYYY HH:mm:ss"
                          />
                        </span>
                      </Box>

                      <QuillWrapperBottom>
                        <ReactQuill
                          theme="bubble"
                          value={comment?.description}
                          readOnly={true}
                        />
                      </QuillWrapperBottom>

                      <Box className={classes.singleCommentAttachedImages}>
                        {comment?.attachedFiles?.map((file) => (
                          <Box component="span" key={file.name}>
                            <ShowAttachedFile
                              file={file}
                              setOpen={setOpen}
                              selectedImage={setSelectedImage}
                            />
                          </Box>
                        ))}
                        {open && (
                          <CustomModal
                            open={open}
                            handleClose={handleClose}
                            bp="lg"
                          >
                            <img src={selectedImage} alt={selectedImage} />
                          </CustomModal>
                        )}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
