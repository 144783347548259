import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Breadcrumbs,
  Box,
  Button as MuiButton,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  Typography,
  Card,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import api from "../../api/api";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

const QuillWrapperTop = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  InputLabel: {
    opacity: 0.6,
    padding: " 5px 10px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  buttons: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-end",
  },
  createButton: {
    color: "white",
    padding: "8px 20px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },
  cancelButton: {
    color: "white",
    padding: "8px 20px",
    background: "#c71414",
    "&:hover": {
      background: "#ff0000",
    },
  },
}));
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  order: Yup.number().integer().min(1).required("Required"),
});

function CreateGettingStarted() {
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const auth = useSelector((state) => state.authReducer);
  const handleCancelGettingStarted = () => {
    history.push("/getting-started");
  };
  const [editorValue, setEditorValue] = useState("");

  if (error) return <div style={{ color: "red" }}>{error}</div>;

  return (
    <Formik
      initialValues={{
        title: "",
        order: 1,
      }}
      validationSchema={validationSchema}
      onSubmit={(
        values,
        { setErrors, resetForm, setStatus, setSubmitting, isSubmitting }
      ) => {
        api
          .post(
            "api/GettingStarted/Create?v=1.0",
            {
              title: values.title,
              content: editorValue,
              order: values.order,
              apiKey: "",
            },
            {
              headers: {
                Authorization: `Bearer ${auth.user?.token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.isSuccess) {
              handleCancelGettingStarted();
              enqueueSnackbar("Tab has been created successfully.", {
                variant: "success",
                autoHideDuration: 3000,
              });
            }
          })
          .catch((error) => {
            setStatus({ sent: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
            setEditorValue("");
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        isSubmitting,
      }) => (
        <>
          <Helmet title="Create Getting Started Tab" />
          <Grid justify="space-between" container spacing={4}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Create Getting Started Tab
              </Typography>

              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Typography>Pages</Typography>
                <Typography>Create Getting Started Tab</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item></Grid>
          </Grid>
          <Divider my={6} />
          <Form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card mb={6}>
                  <CardContent>
                    <TextField
                      name="title"
                      label="Title"
                      variant="outlined"
                      type="text"
                      fullWidth
                      my={2}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                    <QuillWrapperTop>
                      <ReactQuill
                        value={editorValue}
                        onChange={setEditorValue}
                        disabled={isSubmitting}
                        theme="snow"
                      />
                    </QuillWrapperTop>
                    <TextField
                      name="order"
                      label="Order"
                      variant="outlined"
                      type="numeric"
                      fullWidth
                      my={2}
                      error={Boolean(touched.order && errors.order)}
                      helperText={touched.order && errors.order}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />

                    <Box className={classes.buttons}>
                      <Button
                        type="submit"
                        mr={2}
                        variant="contained"
                        className={classes.createButton}
                      >
                        {!isSubmitting && "Create"}
                        {isSubmitting && (
                          <CircularProgress
                            size={18}
                            style={{ color: "white" }}
                          />
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={handleCancelGettingStarted}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default CreateGettingStarted;
