import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Breadcrumbs,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  FormControlLabel,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet-async";
import { spacing } from "@material-ui/system";
import api from "../../api/api";
import Loader from "../../components/Loader";
import {
  Cancel as CancelIcon,
  CloudUpload as MuiCloudUpload,
} from "@material-ui/icons";

import { MemoizedApiScopesTable } from "../../components/tables/ApiScopesTable";

import { useSnackbar } from "notistack";

import CustomModal from "../../components/CustomModal";
import { useHistory } from "react-router-dom";
import { MemoizedNewApiScopesTable } from "../../components/tables/NewApiScopesTable";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const CloudUpload = styled(MuiCloudUpload)(spacing);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  InputLabel: {
    opacity: 0.6,
    padding: " 5px 10px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  attachedFiles: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    margin: "0 4px",
  },

  uploadButton: {
    color: "white",
    padding: "5px 10px",
  },
  cancelIcon: {
    cursor: "pointer",
    position: "absolute",
    top: "-10px",
    right: "-5px",
    transform: "scale(.7)",
    color: "#c71414",
    "&:hover": {
      color: "#ff0000",
    },
  },
  formGroup: {
    border: "1px solid #ddd",
    padding: "10px",
    marginTop: "10px",
    borderRadius: "5px",
  },
  formLabel: {
    marginBottom: "10px",
  },

  buttons: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-end",
  },

  createButton: {
    color: "white",
    padding: "8px 20px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },
  cancelButton: {
    color: "white",
    padding: "8px 20px",
    background: "#c71414",
    "&:hover": {
      background: "#ff0000",
    },
  },
  imagesContainer: {
    height: "55px",
    padding: "0 5px",

    display: "flex",
    alignItems: "center",
  },
  attachedImage: {
    height: "70px",
    width: "70px",
    objectFit: "cover",
    border: "2px solid #ddd",
    cursor: "pointer",
    marginRight: "5px",
  },
}));

const validationSchema = Yup.object().shape({
  appName: Yup.string().required("Required"),
  appDescription: Yup.string().required("Required"),
});

function CreateApplication() {
  const [scopes, setScopes] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [attachedFiles, setAttachedFiles] = useState([]);
  const [showImage, setShowImage] = useState(false);

  const classes = useStyles();

  const auth = useSelector((state) => state.authReducer);
  const selectedScopes = useSelector((state) => state.scopeReducer);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleFileChange = (e) => {
    const newFiles = Object.values(e.target.files);

    const filteredAttachedFiles = newFiles.filter((file) =>
      file.name.match(/\.(jpg|jpeg|png)$/i)
    );

    const fileSize = filteredAttachedFiles.size;
    if (fileSize > 102400) {
      enqueueSnackbar("File size is too big! Please upload a smaller image", {
        variant: "warning",
        autoHideDuration: 3000,
      });
    } else {
      const newFiles = Object.values(e.target.files);
      const filteredAttachedFiles = newFiles.filter((file) =>
        file.name.match(/\.(jpg|jpeg|png)$/i)
      );

      setAttachedFiles(filteredAttachedFiles);
    }
    e.target.value = null;
  };

  const handleCancelAttachedFile = (e) => {
    const newAttachedFiles = attachedFiles.filter((file) => file.name !== e);
    setAttachedFiles(newAttachedFiles);
  };

  const handleCancelApplication = () => {
    history.push("/applications");
  };

  const handleCloseImage = (e) => {
    setShowImage(false);
  };

  useEffect(() => {
    api
      .get("api/PartnerApplication/Scopes?v=1.0", {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setScopes(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <div style={{ color: "red" }}>{error}</div>;

  return (
    <Formik
      initialValues={{
        appName: "",
        appDescription: "",
        isActive: true,
      }}
      validationSchema={validationSchema}
      onSubmit={(
        values,
        { setErrors, resetForm, setStatus, setSubmitting }
      ) => {
        let aFiles = [];
        let promises = [];

        const createError = (error) => {
          setStatus({ sent: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        };

        const createSubmit = async () => {
          if (selectedScopes.scopes.length === 0) {
            enqueueSnackbar("Please select at least one endpoint", {
              variant: "warning",
              autoHideDuration: 3000,
            });
            setStatus({ sent: false });
            setSubmitting(false);
          } else {
            return api
              .post(
                "api/PartnerApplication/Create?v=1.0",
                {
                  name: values.appName,
                  logoPath: aFiles.length > 0 ? aFiles[0].path : null,
                  description: values.appDescription,
                  scopes: selectedScopes.scopes,
                  statusId: values.isActive ? 1 : 2,
                },
                {
                  headers: {
                    Authorization: `Bearer ${auth.user?.token}`,
                  },
                }
              )
              .then((response) => {
                if (response.data.isSuccess) {
                  setStatus({ sent: false });
                  setSubmitting(false);
                  handleCancelApplication();
                  enqueueSnackbar("Application created successfully", {
                    variant: "success",
                    autoHideDuration: 3000,
                  });
                } else {
                  if (
                    response.data.errorMessage === "Application with same name"
                  ) {
                    setStatus({ sent: false });
                    setSubmitting(false);
                    enqueueSnackbar("Application already exists", {
                      variant: "warning",
                      autoHideDuration: 3000,
                    });
                  }
                }
              });
          }
        };

        attachedFiles.forEach((file) => {
          const formData = new FormData();
          formData.append("File", file);
          promises.push(
            api
              .post("api/File/upload?v=1.0", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${auth.user?.token}`,
                },
              })
              .then((response) => {
                aFiles.push({
                  id: response.data.data.id,
                  path: response.data.data.path,
                  mimeType: file.type,
                });
              })
          );
        });

        if (promises.length > 0) {
          Promise.all(promises).then(createSubmit).catch(createError);
        } else {
          createSubmit().catch(createError);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        isSubmitting,
      }) => (
        <>
          {showImage && (
            <CustomModal
              open={showImage}
              handleClose={handleCloseImage}
              bp="md"
            >
              {
                <img
                  src={URL.createObjectURL(attachedFiles[0])}
                  alt={attachedFiles[0].name}
                  onClick={() => {
                    setShowImage(true);
                  }}
                />
              }
            </CustomModal>
          )}

          <Helmet title="Applications" />

          <Grid justify="space-between" container spacing={4}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Applications
              </Typography>

              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Typography>Pages</Typography>
                <Typography>Applications</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item></Grid>
          </Grid>

          <Divider my={6} />

          <Form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card mb={6}>
                  <CardContent>
                    <TextField
                      name="appName"
                      label="Application Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                      my={2}
                      error={Boolean(touched.appName && errors.appName)}
                      helperText={touched.appName && errors.appName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                    <TextField
                      name="appDescription"
                      label="Application Description"
                      variant="outlined"
                      type="text"
                      fullWidth
                      multiline
                      rows={4}
                      my={2}
                      error={Boolean(
                        touched.appDescription && errors.appDescription
                      )}
                      helperText={
                        touched.appDescription && errors.appDescription
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />

                    <Box marginY={2}>
                      {/* <MemoizedApiScopesTable scopes={scopes} /> */}
                      <MemoizedNewApiScopesTable scopes={scopes} />
                    </Box>
                    <Grid container>
                      <Grid item md={6} xs={12}>
                        <Box mt={4} className={classes.imagesContainer}>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="raised-button-file"
                            type="file"
                            onChange={(e) => handleFileChange(e)}
                          />
                          <label htmlFor="raised-button-file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              className={classes.uploadButton}
                              disabled={isSubmitting}
                            >
                              <CloudUpload mr={2} /> logo
                            </Button>
                          </label>

                          {attachedFiles.map((file) => (
                            <span
                              className={classes.attachedFiles}
                              key={file.name}
                            >
                              <CancelIcon
                                className={classes.cancelIcon}
                                onClick={handleCancelAttachedFile.bind(
                                  this,
                                  file?.name
                                )}
                              />

                              <img
                                className={classes.attachedImage}
                                src={URL.createObjectURL(file)}
                                alt={file?.name}
                                onClick={() => {
                                  setShowImage(true);
                                }}
                              />
                            </span>
                          ))}
                        </Box>
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <Box className={classes.buttons}>
                          <FormControlLabel
                            name="isActive"
                            control={<Checkbox defaultChecked />}
                            label="Publish"
                            value={values.isActive}
                            onChange={handleChange}
                            disabled={isSubmitting}
                          />

                          <Button
                            type="submit"
                            mr={2}
                            variant="contained"
                            className={classes.createButton}
                          >
                            {!isSubmitting && "Create"}
                            {isSubmitting && (
                              <CircularProgress
                                size={18}
                                style={{ color: "white" }}
                              />
                            )}
                          </Button>
                          <Button
                            variant="contained"
                            className={classes.cancelButton}
                            onClick={handleCancelApplication}
                            disabled={isSubmitting}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default CreateApplication;
