import React from "react";

import { Checkbox } from "@material-ui/core";

import { DataGrid } from "@mui/x-data-grid";

export default function PermissionTable({ permissions }) {
  const cols = [
    {
      field: "name",
      headerName: "Endpoint Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "get",
      headerName: "Get",
      width: 100,
      align: "center",
      renderCell: (params) => {
        if (!params.row.get) {
          return "";
        } else {
          return (
            <Checkbox
              checked={params.value}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          );
        }
      },
    },

    {
      field: "post",
      headerName: "Post",
      sortable: false,
      width: 100,
      align: "center",
      renderCell: (params) => {
        if (!params.row.post) {
          return "";
        } else {
          return (
            <Checkbox
              checked={params.value}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          );
        }
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 100,
      align: "center",
      renderCell: (params) => {
        if (!params.row.delete) {
          return "";
        } else {
          return (
            <Checkbox
              checked={params.value}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          );
        }
      },
    },
  ];

  return (
    <div style={{ height: 470, width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },

          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        rows={permissions.scopes}
        columns={cols}
        rowsPerPageOptions={[10, 50, 100]}
        disableSelectionOnClick
        scrollbarSize={30}
        hideFooter
      />
    </div>
  );
}
