import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import pdf from "../vendor/pdf.png";
import excel from "../vendor/excel.png";
import word from "../vendor/word.png";
import txt from "../vendor/txt.png";

const useStyles = makeStyles((theme) => ({
  attachedImage: {
    height: "30px",
    width: "70px",
    objectFit: "cover",
    border: "2px solid #ddd",
    cursor: "pointer",
    marginRight: "5px",

    "&:hover": {
      border: "2px solid #a6a6a6",
    },
  },
}));

function AttachedFile({ file }) {
  const classes = useStyles();
  if (
    file?.name?.includes("jpeg") ||
    file?.name?.includes("jpg") ||
    file?.name?.includes("png")
  ) {
    return (
      <img
        src={URL.createObjectURL(file)}
        alt={file}
        className={classes.attachedImage}
      />
    );
  }
  if (file?.name.includes("pdf")) {
    return (
      <>
        <Tooltip title={file?.name}>
          <img src={pdf} alt={file?.name} />
        </Tooltip>
      </>
    );
  }
  if (file?.name.includes("doc") || file?.name.includes("docx")) {
    return (
      <>
        <Tooltip title={file?.name}>
          <img src={word} alt={file?.name} />
        </Tooltip>
      </>
    );
  }
  if (file?.name.includes("xls") || file?.name.includes("xlsx")) {
    return (
      <>
        <Tooltip title={file?.name}>
          <img src={excel} alt={file?.name} />
        </Tooltip>
      </>
    );
  }
  if (file?.name.includes("txt")) {
    return (
      <>
        <Tooltip title={file?.name}>
          <img src={txt} alt={file?.name} />
        </Tooltip>
      </>
    );
  }
}

export default AttachedFile;
