import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet-async";
import Loader from "../../components/Loader";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import api from "../../api/api";
import PartnerAccountsTable from "../../components/tables/PartnerAccountsTable.";
import { setIsGridLoading } from "../../redux/actions/datagridActions";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

function PartnerAccounts() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);

  const [partnerAccState, setPartnerAccState] = useState({
    partners: [],
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    api
      .get(`api/Partner/GetPartners?&v=1.0`, {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setPartnerAccState({
            partners: response.data.data,
            totalCount: response.data.totalCount,
            isLoading: false,
            error: null,
          });
          dispatch(setIsGridLoading(false));
        }
      })
      .catch((error) => {
        setPartnerAccState({
          ...partnerAccState,
          isLoading: false,
          error: error.message,
        });
      });
  }, []);

  if (partnerAccState?.isLoading) return <Loader />;
  if (partnerAccState?.error)
    return <div style={{ color: "red" }}>{partnerAccState.error}</div>;

  return (
    <React.Fragment>
      <Helmet title="PartnerAccounts" />

      <Typography variant="h3" gutterBottom display="inline">
        Partner Accounts
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Pages</Typography>
        <Typography>Partner Accounts</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <PartnerAccountsTable partners={partnerAccState.partners} />
    </React.Fragment>
  );
}

export default PartnerAccounts;
