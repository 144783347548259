import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Breadcrumbs,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  FormControl,
  MenuItem,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { Helmet } from "react-helmet-async";

import styled from "styled-components/macro";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import api from "../../api/api";
import Loader from "../../components/Loader";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  InputLabel: {
    opacity: 0.6,
    padding: " 5px 10px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  buttons: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-end",
  },
  createButton: {
    color: "white",
    padding: "8px 20px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },
  cancelButton: {
    color: "white",
    padding: "8px 20px",
    background: "#c71414",
    "&:hover": {
      background: "#ff0000",
    },
  },
}));

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string()
    .email()
    .matches(
      /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!outlook.com)([\w-]+\.)+[\w-]{2,4})?$/,
      "free email services will not be allowed."
    )
    .required("Required"),
  role: Yup.string().required("Select Role"),
});

function CreateMember({ isSubmitted }) {
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const classes = useStyles();

  const auth = useSelector((state) => state.authReducer);

  const handleCancelMember = () => {
    history.push("/manageteam");
  };

  useEffect(() => {
    api
      .get("api/User/Roles?v=1.0", {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRoles(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  }, []);
  if (isLoading) return <Loader />;
  if (error) return <div style={{ color: "red" }}>{error}</div>;

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        role: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(
        values,
        { setErrors, resetForm, setStatus, setSubmitting, isSubmitting }
      ) => {
        api
          .post(
            "api/User/Create?v=1.0",
            {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              partnerId: auth?.user?.pid,
              idsRole: [values.role],
              apiKey: "",
            },
            {
              headers: {
                Authorization: `Bearer ${auth.user?.token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.isSuccess) {
              handleCancelMember();
              enqueueSnackbar("Member has been created successfully.", {
                variant: "success",
                autoHideDuration: 3000,
              });
            } else if (response.data.message === "User Exist") {
              enqueueSnackbar("There is a registered user with this email", {
                variant: "warning",
                autoHideDuration: 3000,
              });
              setStatus({ sent: false });
              setSubmitting(false);
            }
          })
          .catch((error) => {
            setStatus({ sent: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        isSubmitting,
      }) => (
        <>
          <Helmet title="ManageTeam" />

          <Grid justify="space-between" container spacing={4}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Manage Team
              </Typography>

              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Typography>Pages</Typography>
                <Typography>Manage Team</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item></Grid>
          </Grid>
          <Divider my={6} />
          <Form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card mb={6}>
                  <CardContent>
                    <TextField
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                      my={2}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      inputProps={{ maxLength: 120 }}
                    />
                    <TextField
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                      my={2}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      inputProps={{ maxLength: 120 }}
                    />
                    <TextField
                      name="email"
                      type="email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      my={2}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      inputProps={{ maxLength: 255 }}
                    />
                    <FormControl fullWidth>
                      <TextField
                        name="role"
                        label="Roles"
                        select
                        variant="outlined"
                        value={values.role ?? ""}
                        fullWidth
                        my={2}
                        error={Boolean(touched.role && errors.role)}
                        helperText={touched.role && errors.role}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      >
                        {roles.map((role) => (
                          <MenuItem value={role?.id} key={role?.id}>
                            {role?.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>

                    <Box className={classes.buttons}>
                      <Button
                        type="submit"
                        mr={2}
                        variant="contained"
                        className={classes.createButton}
                      >
                        {!isSubmitting && "Create"}
                        {isSubmitting && (
                          <CircularProgress
                            size={18}
                            style={{ color: "white" }}
                          />
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={handleCancelMember}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default CreateMember;
