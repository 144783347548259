import styled from "styled-components/macro";
import { ReactComponent as Logo } from "../vendor/logo.svg";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { signOut } from "../redux/actions/authActions";

const Container = styled(Box)`
  display: "flex";
  justify-content: "center";
  cursor: pointer;
`;

const BigLogo = styled(Logo)`
  width: 300px;
  height: 50px;
  margin-bottom: ${(props) => props.mb};

  @media (max-width: 600px) {
    width: 200px;
    height: 40px;
  }
`;

function AppLogo({ mb }) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Container
      display="flex"
      justifyContent="center"
      onClick={() => {
        history.push("/");
        dispatch(signOut());
      }}
    >
      <BigLogo mb={mb} />
    </Container>
  );
}

export default AppLogo;
