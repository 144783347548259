import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import pdf from "../vendor/pdf.png";
import excel from "../vendor/excel.png";
import word from "../vendor/word.png";
import txt from "../vendor/txt.png";

const useStyles = makeStyles((theme) => ({
  attachedImage: {
    height: "30px",
    width: "70px",
    objectFit: "cover",
    border: "2px solid #ddd",
    cursor: "pointer",
    marginRight: "5px",

    "&:hover": {
      border: "2px solid #a6a6a6",
    },
  },
}));

function AttachedFile({ file, setOpen, selectedImage }) {
  const arr = file.path.split("/");
  const fileName = arr[arr.length - 1];
  const classes = useStyles();
  if (
    file?.path.endsWith("jpeg") ||
    file?.path.endsWith("jpg") ||
    file?.path.endsWith("png")
  ) {
    return (
      <img
        src={file.path}
        alt={file.path}
        className={classes.attachedImage}
        onClick={() => {
          selectedImage(file?.path);
          setOpen(true);
        }}
      />
    );
  }
  if (file?.path.endsWith("pdf")) {
    return (
      <Tooltip title={fileName}>
        <a href={file?.path} download target="_self">
          <img src={pdf} alt={file?.path} />
        </a>
      </Tooltip>
    );
  }
  if (file?.path.endsWith("doc") || file?.path.endsWith("docx")) {
    return (
      <a href={file?.path} download target="_self">
        <Tooltip title={fileName}>
          <img src={word} alt={file?.path} />
        </Tooltip>
      </a>
    );
  }
  if (file?.path.endsWith("xls") || file?.path.endsWith("xlsx")) {
    return (
      <a href={file?.path} download target="_self">
        <Tooltip title={fileName}>
          <img src={excel} alt={file?.path} />
        </Tooltip>
      </a>
    );
  }
  if (file?.path.endsWith("txt")) {
    return (
      <a href={file?.path} download target="_self">
        <Tooltip title={fileName}>
          <img src={txt} alt={file?.path} />
        </Tooltip>
      </a>
    );
  }
  return null;
}

export default AttachedFile;
