import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Formik, Form } from "formik";
import { useSnackbar } from "notistack";
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography,
  Button as MuiButton,
  TextField as MuiTextField,
  CircularProgress,
  CardContent,
  Card,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import * as Yup from "yup";
import api from "../../api/api";
import Loader from "../../components/Loader";
import { useHistory, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

const Grid = styled(MuiGrid)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const TextField = styled(MuiTextField)(spacing);
const QuillWrapperTop = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  InputLabel: {
    opacity: 0.6,
    padding: " 5px 10px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  buttons: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-end",
  },
  createButton: {
    color: "white",
    padding: "8px 20px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },
  cancelButton: {
    color: "white",
    padding: "8px 20px",
    background: "#c71414",
    "&:hover": {
      background: "#ff0000",
    },
  },
  activeButtonInDialog: {
    color: "white",
    marginRight: "5px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },
  cancelIcon: {
    cursor: "pointer",
    "&:hover": {
      opacity: "0.6",
    },
  },
  dialogTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px 0  0",
  },
}));

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  order: Yup.number().integer().min(1).required("Required"),
});

export default function GettingstartedDetail() {
  const history = useHistory();
  const { gettingstartedid } = useParams();
  const auth = useSelector((state) => state.authReducer);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [error, setError] = useState(null);
  const [gettingStarted, setGettingStarted] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleDeleteGettingStarted = () => {
    setIsDeleting(true);
    api
      .delete(`api/GettingStarted/Delete/${gettingstartedid}?v=1.0`, {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        setIsDeleting(false);
        history.push("/getting-started");
        enqueueSnackbar(
          `The tab ${gettingStarted.title} has been deleted successfully.`,
          {
            variant: "success",
            autoHideDuration: 3000,
          }
        );
      });
  };

  const handleCloseDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const handleShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const setGettingStartedContent = (e) => {
    let obj = Object.assign({}, gettingStarted);
    obj.content = e;
    setGettingStarted(obj);
  };

  useEffect(() => {
    api
      .get(`api/GettingStarted/GetGettingStarted/${gettingstartedid}?v=1.0`, {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setGettingStarted(response.data.data);
          setIsLoading(false);
        }
      });
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <div style={{ color: "red" }}>{error}</div>;

  return (
    <Formik
      initialValues={{
        title: gettingStarted?.title,
        order: gettingStarted?.order,
      }}
      validationSchema={validationSchema}
      onSubmit={(
        values,
        { setErrors, resetForm, setStatus, setSubmitting, isSubmitting }
      ) => {
        api
          .put(
            `api/GettingStarted/Update/${gettingstartedid}?v=1.0`,
            {
              title: values.title,
              content: gettingStarted?.content,
              order: values.order,
              apiKey: "",
            },
            {
              headers: {
                Authorization: `Bearer ${auth.user?.token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.isSuccess) {
              history.push("/getting-started");
              enqueueSnackbar("Tab has been updated successfully.", {
                variant: "success",
                autoHideDuration: 3000,
              });
            }
          })
          .catch((error) => {
            setStatus({ sent: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        isSubmitting,
      }) => (
        <>
          {showDeleteConfirmation && (
            <Dialog
              open={showDeleteConfirmation}
              onClose={handleCloseDeleteConfirmation}
            >
              <Box className={classes.dialogTitleContainer}>
                <DialogTitle className={classes.dialogTitle}>
                  Confirmation
                </DialogTitle>
                <CloseIcon
                  onClick={handleCloseDeleteConfirmation}
                  className={classes.cancelIcon}
                />
              </Box>
              <DialogContent>
                <Typography variant="body1" component="p">
                  Are you sure you want to delete {gettingStarted?.title} tab ?
                </Typography>
                <Box className={classes.buttons}>
                  <Button
                    type="submit"
                    mr={2}
                    variant="contained"
                    size="small"
                    className={classes.cancelButton}
                    onClick={handleDeleteGettingStarted}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.deactiveButtonInDialog}
                    size="small"
                    onClick={handleCloseDeleteConfirmation}
                  >
                    Cancel
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          )}
          <Helmet title="Update Getting Started Tab" />
          <Grid justify="space-between" container spacing={4}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Create Getting Started Tab
              </Typography>

              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Typography>Pages</Typography>
                <Typography>Create Getting Started Tab</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item></Grid>
          </Grid>
          <Divider my={6} />
          <Box className={classes.detailHeader}>
            <IconButton
              onClick={() => {
                history.push("/getting-started");
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Box>
          <Form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card mb={6}>
                  <CardContent>
                    <TextField
                      name="title"
                      defaultValue={values.title}
                      label="Title"
                      variant="outlined"
                      type="text"
                      fullWidth
                      my={2}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                    <QuillWrapperTop>
                      <ReactQuill
                        value={gettingStarted?.content}
                        onChange={setGettingStartedContent}
                        disabled={isSubmitting}
                        theme="snow"
                      />
                    </QuillWrapperTop>
                    <TextField
                      name="order"
                      defaultValue={values.order}
                      label="Order"
                      variant="outlined"
                      type="numeric"
                      fullWidth
                      my={2}
                      error={Boolean(touched.order && errors.order)}
                      helperText={touched.order && errors.order}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />

                    <Box className={classes.buttons}>
                      <Button
                        type="submit"
                        mr={2}
                        variant="contained"
                        className={classes.createButton}
                      >
                        {!isSubmitting && "Update"}
                        {isSubmitting && (
                          <CircularProgress
                            size={18}
                            style={{ color: "white" }}
                          />
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={handleShowDeleteConfirmation}
                        disabled={isSubmitting || isDeleting}
                      >
                        {!isDeleting && "Delete"}
                        {isDeleting && (
                          <CircularProgress
                            size={18}
                            style={{ color: "white" }}
                          />
                        )}
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
}
