import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import partnerReducer from "./partnerReducer";

import scopeReducer from "./scopeReducer";
import datagridReducer from "./datagridReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  partnerReducer,
  scopeReducer,
  datagridReducer,
});
