import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { withTheme } from "styled-components/macro";
import api from "../api/api";

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import UserDropdown from "./UserDropdown";

import { useSnackbar } from "notistack";
import * as types from "../constants";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const LogoAndCompanyNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CompanyName = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
`;

const AddLogo = styled.div`
  border: ${(props) => (props.hasLogo ? "none" : "2px dashed #ccc;")};
  width: 70px;
  height: 50px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  line-height: 1;
  padding: 5px;

  span {
    font-size: 12px;
  }
`;

const ImageContainer = styled.div`
  width: 70px;
  height: 50px;
  cursor: ${(props) => (props.opacity ? "pointer" : "default")};
  position: relative;
  z-index: 1;
  transition: all 2s ease-in;

  &:before {
    opacity: 0;
    content: "upload \n logo";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #4d5658;
    font-size: 13px;
    font-weight: bold;
    background: rgb(223, 225, 229, 0.9);
    z-index: 2;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &:hover {
    &:before {
      opacity: ${(props) => props.opacity};
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const AppBarComponent = ({ onDrawerToggle }) => {
  const auth = useSelector((state) => state.authReducer);
  console.log(auth?.user?.rol);
  const dispatch = useDispatch();

  const [attachedLogo, setAttachedLogo] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    handleLogoUpload();
  }, [attachedLogo]);

  const handleLogoUpload = (e) => {
    let aFiles = [];
    let promises = [];

    attachedLogo.forEach((file) => {
      const formData = new FormData();
      formData.append("File", file);
      promises.push(
        api
          .post("api/File/upload?v=1.0", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${auth.user?.token}`,
            },
          })
          .then((response) => {
            aFiles.push({
              id: response.data.data.id,
              path: response.data.data.path,
              mimeType: file.type,
            });
          })
      );
    });

    const updateSubmit = async () => {
      return api
        .put(
          `api/Partner/UpdateLogo/${auth?.user?.pid}?v=1.0&logoPath=${aFiles[0]?.path}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${auth.user?.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: types.AUTH_SIGN_IN_SUCCESS,
              ...auth?.user,
              companyLogo: aFiles[0]?.path,
            });
          }
        });
    };

    if (promises.length > 0) {
      Promise.all(promises).then(updateSubmit);
    }
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const fileSize = file.size;
    if (fileSize > 102400) {
      enqueueSnackbar("File size is too big! Please upload a smaller image", {
        variant: "warning",
        autoHideDuration: 3000,
      });
    } else {
      setAttachedLogo([...e.target.files]);
    }
    e.target.value = null;
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs>
              <LogoAndCompanyNameContainer>
                {auth?.user?.rol && !auth?.user?.rol.startsWith("Bromcom") && (
                  <>
                    {auth?.user?.rol === "PartnerAccountOwner" && (
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="app-logo-upload"
                        type="file"
                        onChange={(e) => handleLogoChange(e)}
                      />
                    )}

                    <label htmlFor="app-logo-upload">
                      <AddLogo
                        hasLogo={
                          auth?.user?.companyLogo || attachedLogo.length > 0
                            ? true
                            : false
                        }
                      >
                        {!auth?.user?.companyName ? (
                          <>
                            <span>INSERT</span>
                            <span>LOGO</span>
                            <span>HERE</span>
                          </>
                        ) : (
                          <ImageContainer
                            opacity={
                              auth?.user?.rol === "PartnerAccountOwner" ? 1 : 0
                            }
                          >
                            <Image
                              src={
                                attachedLogo.length > 0
                                  ? URL.createObjectURL(attachedLogo[0])
                                  : auth?.user?.companyLogo
                              }
                              alt="logo"
                              opacity={
                                auth?.user?.rol === "PartnerAccountOwner"
                                  ? 1
                                  : 0
                              }
                            />
                          </ImageContainer>
                        )}
                      </AddLogo>
                    </label>
                  </>
                )}

                <CompanyName>{auth?.user?.companyName}</CompanyName>
              </LogoAndCompanyNameContainer>
            </Grid>

            <Grid item>
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);
