import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import * as Yup from "yup";
import { Formik } from "formik";
import ReactHtmlParser from "react-html-parser";
import { signUp } from "../../services/authService";

import AppLogo from "../../components/AppLogo";
import ReCAPTCHA from "react-google-recaptcha";

import CustomModal from "../../components/CustomModal";

import {
  Button,
  Card as MuiCard,
  CardContent,
  Checkbox,
  Paper,
  Snackbar,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import TrapFocus from "@mui/material/Unstable_TrapFocus";

import { Alert as MuiAlert } from "@material-ui/lab";
import api from "../../api/api";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Card = styled(MuiCard)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }

  background-color: #f9f8fd;
`;

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "520px",
    padding: "1rem",
    boxShadow: "0 1px 5px 2px rgba(0, 0, 0, .2)",
  },

  signUpButton: {
    color: "white",
    backgroundColor: "#1D88E4",
    "&:hover": { backgroundColor: "#176dea" },
  },

  recaptchaWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "15px",

    [theme.breakpoints.down("400")]: {
      justifyContent: "flex-start",
      "& >*": {
        transformOrigin: "0 0",
        width: "200px",
        transform: "scale(0.8)",
      },
    },

    [theme.breakpoints.down("350")]: {
      justifyContent: "flex-start",
      "& >*": {
        transformOrigin: "0 0",
        transform: "scale(0.72)",
      },
    },
  },

  recaptcha: {
    marginBottom: "10px",
  },
  recaptchaMessage: {
    width: "100%",
  },
  termsAndCondMessage: {
    marginBottom: "15px",
  },
  clickHere: {
    cursor: "pointer",
    borderBottom: "1px solid black",
  },
}));

const TermsAndCondContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;  
`;
const TermsAndCondText = styled.p`
  margin: 0;
  margin-left: 0px;
  margin-top: 2px;
  color: #565b73;
`;

const TermsAndCondLink = styled(Link)`
  text-decoration: underline;
  color: #565b73;
  &:hover {
    color: #383a42;
  }
`;

const TermsAndCondModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TermsAndCondModalTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
`;
const TermsAndCondModalText = styled.div`
  font-size: 14px;
  height: 400px;
  overflow-y: scroll;
  margin-bottom: 10px;
`;

const TermsAndCondModalButtons = styled.div`
  padding-top: 20px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
`;

const TermsAndCondCancelButton = styled(Button)`
  background-color: white;
  border: 1px solid #ddd;
  color: #5c5c5c;
  font-size: 14px;
  width: 48%;
  text-transform: Capitalize;
`;

const TermsAndCondAcceptButton = styled(Button)`
  background-color: #1d88e4;
  color: white;
  font-size: 14px;
  color: white;
  width: 48%;
  text-transform: Capitalize;

  &:hover {
    background-color: #176dea;
  }
`;

const initialValues = {
  organizationName: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  websiteAddress: "",
  rolePosition: "",
  noOfBromcomCustomers: "",
  services: "",
  plannedIntegrations: "",
};

const validationSchema = Yup.object().shape({
  organizationName: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string()
    .email()
    .matches(
      /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!outlook.com)([\w-]+\.)+[\w-]{2,4})?$/,
      "free email services will not be allowed."
    )
    .required("Required"),
  phoneNumber: Yup.string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "telephone Number is not valid"
    ),
  websiteAddress: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "enter correct url"
    )
    .required("Required"),
  rolePosition: Yup.string().required("Required"),
  noOfBromcomCustomers: Yup.string().required("Required"),
  services: Yup.string().required("Required"),
  plannedIntegrations: Yup.string().required("Required"),
});

function SignUp() {
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [partnerData, setPartnerData] = useState(null);
  const [formValues, setsFormValues] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [showRecaptchaMessage, setShowRecaptchaMessage] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredOrgName, setEnteredOrgName] = useState("");
  const [enteredWebsite, setEnteredWebsite] = useState("");
  const [showTermsAndCondModal, setShowTermsAndCondModal] = useState(false);
  const [acceptTermsAndCond, setAcceptTermsAndCond] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const auth = useSelector((state) => state.authReducer);

  const classes = useStyles();

  const recaptchaChangeHandler = () => {
    setIsVerified(true);
    setShowRecaptchaMessage(false);
  };

  const handleShowTermsAndCondModal = () => {
    setShowTermsAndCondModal(true);
  };
  const handleCloseTermsAndCondModal = () => {
    setShowTermsAndCondModal(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleAcceptTermsAndCond = () => {
    setAcceptTermsAndCond(true);
    setShowTermsAndCondModal(false);
  };

  const handleTouched = () => {
    setIsTouched(true);
  };

  const handleCheckAcceptTermsAndCond = (e) => {
    if (e.target.checked) {
      setAcceptTermsAndCond(true);
    } else {
      setAcceptTermsAndCond(false);
    }
  };

  const handlePermission = () => {
    api
      .post("api/Partner/PermissionRequest?v=1.0", {
        organizationName: formValues.organizationName.toLowerCase(),
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        phone: formValues.phoneNumber,
        email: formValues.email.toLowerCase(),
        emailDomain: formValues.email.split("@")[1].toLowerCase(),
        website: formValues.websiteAddress,
        role: formValues.rolePosition,
        servicesProvidedToBC: formValues.services,
        plannedIntegrations: formValues.plannedIntegrations,
        customerCount: formValues.noOfBromcomCustomers,
        partnerStatusId: 0,
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setOpenSnackBar(true);
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    api
      .get(`api/UserInteraction/TermsAndConditions?&v=1.0`, {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setModalContent(response.data.data);
        }
      });
  }, []);

  return (
    <>
      {showTermsAndCondModal && (
        <CustomModal
          open={showTermsAndCondModal}
          handleClose={handleCloseTermsAndCondModal}
          bp="md"
        >
          <TermsAndCondModalContainer>
            <TermsAndCondModalTitle>
              Terms and Conditions
            </TermsAndCondModalTitle>
            <TermsAndCondModalText>
              {ReactHtmlParser(modalContent)}
            </TermsAndCondModalText>

            <TermsAndCondModalButtons>
              <TermsAndCondCancelButton onClick={handleCloseTermsAndCondModal}>
                Cancel
              </TermsAndCondCancelButton>
              <TermsAndCondAcceptButton onClick={handleAcceptTermsAndCond}>
                Agree
              </TermsAndCondAcceptButton>
            </TermsAndCondModalButtons>
          </TermsAndCondModalContainer>
        </CustomModal>
      )}
      <Wrapper>
        <Helmet title="Sign Up" />
        <AppLogo mb="20px" />
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Partner Registration
            </Typography>
            <Typography variant="body2" gutterBottom>
              Register by completing the registration form below.
            </Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (
                values,
                { setErrors, resetForm, setSubmitting, setStatus }
              ) => {
                if (isVerified && acceptTermsAndCond) {
                  setEnteredEmail(values.email.toLowerCase());
                  setEnteredWebsite(values.websiteAddress.toLowerCase());
                  setEnteredOrgName(values.organizationName.toLowerCase());
                  setsFormValues(values);

                try {
                  const response = await signUp({
                    organizationName: values.organizationName,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phone: values.phoneNumber,
                    email: values.email.toLowerCase(),
                    emailDomain: values.email.split("@")[1].toLowerCase(),
                    website: values.websiteAddress,
                    role: values.rolePosition,
                    servicesProvidedToBC: values.services,
                    plannedIntegrations: values.plannedIntegrations,
                    customerCount: values.noOfBromcomCustomers,
                    partnerStatusId: 0,
                    partnerLogoPath: "",
                  });

                  if (response.data === 0) {
                    setPartnerData(response);

                    setStatus({ success: false });
                    setSubmitting(false);
                    resetForm();
                  } else {
                    setPartnerData(response);
                    setStatus({ success: false });

                    setSubmitting(false);
                  }
                } catch (error) {
                  const message = error.message || "Something went wrong";

                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              } else {
                setShowRecaptchaMessage(true);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              isSubmitting,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <TrapFocus disableEnforceFocus open>
                    <Paper tabIndex={-1}>
                      <Alert mt={2} mb={1} severity="warning">
                        {errors.submit}
                      </Alert>
                    </Paper>
                  </TrapFocus>
                )}
                {partnerData?.data === 0 && (
                  <TrapFocus disableEnforceFocus open>
                    <Paper tabIndex={-1}>
                      <Alert mt={5} severity="success">
                        Registration request is received. Bromcom Partner Team
                        will respond to your request within 3 working days via
                        <strong> {enteredEmail}</strong>
                      </Alert>
                    </Paper>
                  </TrapFocus>
                )}
                {partnerData?.data === 1 && (
                  <TrapFocus disableEnforceFocus open>
                    <Paper tabIndex={-1}>
                      <Alert mt={5} severity="warning">
                        Registration request is pending for the entered
                        <strong> {enteredEmail}</strong> or
                        <strong> {enteredOrgName}</strong> or
                        <strong> {enteredWebsite}</strong>.If you request is
                        older than 3 working days, please send email to
                        <b> Partner.Portal@bromcom.com</b> with your details
                      </Alert>
                    </Paper>
                  </TrapFocus>
                )}
                {partnerData?.data === 2 && (
                  <TrapFocus disableEnforceFocus open>
                    <Paper tabIndex={-1}>
                      <Alert mt={5} severity="warning">
                        Already active partner account exists for the entered
                        <strong> {enteredEmail}</strong> or
                        <strong> {enteredOrgName}</strong> or
                        <strong> {enteredWebsite}</strong>. You can send access
                        request to the account owner by sharing the entered
                        details during the registration process, if you want to
                        send the request, please&nbsp;
                        <strong
                          onClick={handlePermission}
                          className={classes.clickHere}
                        >
                          click here
                        </strong>
                      </Alert>
                    </Paper>
                  </TrapFocus>
                )}

                <TextField
                  name="organizationName"
                  label="Organisation Name *"
                  value={values.organizationName}
                  error={Boolean(
                    touched.organizationName && errors.organizationName
                  )}
                  fullWidth
                  helperText={
                    touched.organizationName && errors.organizationName
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  mt={4}
                  mb={4}
                  disabled={isSubmitting}
                />
                <TextField
                  name="firstName"
                  label="First Name *"
                  value={values.firstName}
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  mb={4}
                  disabled={isSubmitting}
                />
                <TextField
                  name="lastName"
                  label="Last Name *"
                  value={values.lastName}
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  mb={4}
                  disabled={isSubmitting}
                />
                <TextField
                  name="email"
                  label="Email Address *"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  mb={4}
                  disabled={isSubmitting}
                />
                <TextField
                  name="phoneNumber"
                  label="Telephone Number *"
                  value={values.phoneNumber}
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  fullWidth
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="tel"
                  mb={4}
                  disabled={isSubmitting}
                />
                <TextField
                  name="websiteAddress"
                  label="Website Address *"
                  value={values.websiteAddress}
                  error={Boolean(
                    touched.websiteAddress && errors.websiteAddress
                  )}
                  fullWidth
                  helperText={touched.websiteAddress && errors.websiteAddress}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="websiteAddress"
                  mb={4}
                  disabled={isSubmitting}
                />
                <TextField
                  name="rolePosition"
                  label="Your Role/Position *"
                  value={values.rolePosition}
                  error={Boolean(touched.rolePosition && errors.rolePosition)}
                  fullWidth
                  helperText={touched.rolePosition && errors.rolePosition}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  mb={4}
                  disabled={isSubmitting}
                />
                <TextField
                  type="number"
                  name="noOfBromcomCustomers"
                  label="No. of Bromcom Customers *"
                  value={values.noOfBromcomCustomers}
                  error={Boolean(
                    touched.noOfBromcomCustomers && errors.noOfBromcomCustomers
                  )}
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 10 } }}
                  helperText={
                    touched.noOfBromcomCustomers && errors.noOfBromcomCustomers
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  mb={4}
                  disabled={isSubmitting}
                />
                <TextField
                  name="services"
                  label="Services Provided to Bromcom Customers *"
                  value={values.services}
                  error={Boolean(touched.services && errors.services)}
                  fullWidth
                  multiline
                  rowsMax={5}
                  helperText={touched.services && errors.services}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  mb={4}
                  disabled={isSubmitting}
                />
                <TextField
                  name="plannedIntegrations"
                  label="Planned Integrations *"
                  value={values.plannedIntegrations}
                  error={Boolean(
                    touched.plannedIntegrations && errors.plannedIntegrations
                  )}
                  fullWidth
                  multiline
                  rowsMax={5}
                  helperText={
                    touched.plannedIntegrations && errors.plannedIntegrations
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  mb={4}
                  disabled={isSubmitting}
                />
                <div className={classes.recaptchaWrapper}>
                  <ReCAPTCHA
                      // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      sitekey="6Ldt9rIUAAAAALSg3SIBPnqn2l-DkwlF1Nqh2JTA"
                    onChange={recaptchaChangeHandler}
                    size="normal"
                    className={classes.recaptcha}
                  />
                  {showRecaptchaMessage && (
                    <Alert
                      severity="warning"
                      className={classes.recaptchaMessage}
                    >
                      Captcha is required
                    </Alert>
                  )}
                </div>
                <div>
                    <TermsAndCondContainer>
                      <Checkbox
                        name="termsAndConditions"
                        checked={acceptTermsAndCond}
                        value={acceptTermsAndCond}
                        onChange={handleCheckAcceptTermsAndCond}
                        style={{paddingTop:0}}
                      />
                      <TermsAndCondText>
                        I accept the{" "}
                        <TermsAndCondLink onClick={handleShowTermsAndCondModal}>
                          Bromcom Terms of Services
                        </TermsAndCondLink>{" "}                      
                      </TermsAndCondText>
                    </TermsAndCondContainer>

                    {!acceptTermsAndCond && isTouched && (
                      <Alert
                        severity="warning"
                        className={classes.termsAndCondMessage}
                      >
                        You need to accept the terms and conditions
                      </Alert>
                    )}
                  </div>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.signUpButton}
                    disabled={isSubmitting}
                    onClick={handleTouched}
                  >
                    {isSubmitting ? "Please wait..." : "Submit"}
                  </Button>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="success">
            Request sent successfully!
          </Alert>
        </Snackbar>
      </Wrapper>
    </>
  );
}

export default SignUp;
