import * as types from "../../constants";

const initialState = {
  partnerStatus: false,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.PARTNER_SUCCESS:
      return {
        ...state,
        activePartner: actions.activePartner,
      };

    case types.PARTNER_SET:
      return {
        ...state,
        partner: actions.partner,
      };

    case types.PARTNER_STATUS:
      return {
        ...state,
        partnerStatus: actions.partnerStatus,
      };

    case types.PARTNERS_GET:
      return {
        ...state,
        partners: actions.partners,
      };

    default:
      return state;
  }
}
