import api from "../api/api";

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    const partnerUrl = "api/Auth/token?v=1.0";
    const bromcomUrl = "api/Auth/AzurAdToken?v=1.0";

    const url = credentials.type === "partner" ? partnerUrl : bromcomUrl;
    delete credentials.type;

    api
      .post(url, credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    api
      .post("api/Partner/AddPartner?v=1.0", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(data) {
  return new Promise((resolve, reject) => {
    api
      .post("api/UserInteraction/ResetPassword?v=1.0", data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function forgotPassword(data) {
  return new Promise((resolve, reject) => {
    api
      .post(`api/UserInteraction/ForgotPassword?email=${data}&v=1.0`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
