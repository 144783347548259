import React, { useState, useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import { SignInButton } from "./SignInButton";
import { signIn } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const ProfileContent = () => {
  const { accounts, instance } = useMsal();
  const auth = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();
  const [error, setError] = useState("");

  useEffect(() => {
    const loginFc = async () => {
      try {
        await dispatch(
          signIn({
            username: accounts[0].username,
            type: "bromcom",
          })
        );
      } catch (error) {
        setError(error.message);
      }
    };

    loginFc();
  }, []);

  function handleLogout(instance) {
    instance
      .logoutRedirect({
        postLogoutRedirectUri: "/admin",
        mainWindowRedirectUri: "/admin",
      })
      .catch((e) => {
        console.error(e);
      });
  }

  if (auth?.isLoading && !error) {
    return <h1>Redirecting...</h1>;
  }

  if (error) {
    return <h3 style={{ color: "red" }}>{error}</h3>;
  }

  if (auth?.isSuccess) {
    return <Redirect to="/applications" />;
  } else {
    setTimeout(() => {
      handleLogout(instance);
    }, 2000);
    return <h3>No bromcom account exits for this mail</h3>;
  }
};

export default function Office365Page() {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <ProfileContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <h5 className="card-title">
          <SignInButton />
        </h5>
      </UnauthenticatedTemplate>
    </div>
  );
}
