import * as types from "../../constants";

const initialState = {
  size: 100,
  page: 0,
  isGridLoading: true,
  totalCount: 0,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GRID_ROWS_PER_PAGE:
      return {
        ...state,
        size: actions.size,
        isGridLoading: true,
      };
    case types.GRID_PAGE:
      return {
        ...state,
        page: actions.page,
        isGridLoading: true,
      };
    case types.GRID_ISLOADING:
      return {
        ...state,
        isGridLoading: actions.isGridLoading,
        totalCount: actions.totalCount,
      };
    default:
      return state;
  }
}
