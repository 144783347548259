import React from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@material-ui/core";

import { signOut } from "../redux/actions/authActions";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function handleLogout(instance) {
  instance
    .logoutRedirect({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    })
    .catch((e) => {
      console.error(e);
    });
}

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const auth = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const { instance } = useMsal();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    if (
      [
        "BromcomSuperUser",
        "BromcomAdmin",
        "BromcomDeveloper",
        "BromcomSupport",
      ].includes(auth?.user?.rol)
    ) {
      handleLogout(instance);
    } else {
      dispatch(signOut());
    }
    localStorage.clear();
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default UserDropdown;
