import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setScopes } from "../../redux/actions/scopeActions";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Checkbox } from "@material-ui/core";
import "@progress/kendo-theme-material/dist/all.css";

const initialFilter = {
  logic: "and",
  filters: [
    {
      field: "name",
      operator: "contains",
      value: "",
    },
  ],
};

function NewApiScopesTable({ scopes }) {
  const dispatch = useDispatch();

  const [rows, setRows] = useState(scopes);
  const [filter, setFilter] = React.useState(initialFilter);
  const [skip, setSkip] = React.useState(0);

  useEffect(() => {
    setRows(scopes);
  }, [scopes]);

  useEffect(() => {
    const selectedRows = rows.filter(
      (row) => !!row.get || !!row.post || !!row.put || !!row.delete
    );
    dispatch(setScopes(selectedRows));
  }, [rows]);

  const handleSelect = (row) => {
    const newRows = [...rows];
    const index = newRows.findIndex((r) => r.id === row.id);
    newRows[index] = { ...row };
    setRows(newRows);
  };

  const getCell = (props) => {
    return (
      <td>
        <Checkbox
          inputProps={{ "aria-label": "primary checkbox" }}
          checked={props.dataItem.get}
          onChange={() =>
            handleSelect({ ...props.dataItem, get: !props.dataItem.get })
          }
        />
      </td>
    );
  };

  const postCell = (props) => {
    if (!props.dataItem.isPostEnable) {
      return <td></td>;
    } else {
      return (
        <td>
          <Checkbox
            inputProps={{ "aria-label": "primary checkbox" }}
            checked={props.dataItem.post}
            onChange={() =>
              handleSelect({ ...props.dataItem, post: !props.dataItem.post })
            }
          />
        </td>
      );
    }
  };  
  const deleteCell = (props) => {
    if (!props.dataItem.isDeleteEnable) {
      return <td></td>;
    } else {
      return (
        <td>
          <Checkbox
            inputProps={{ "aria-label": "primary checkbox" }}
            checked={props.dataItem.delete}
            onChange={() =>
              handleSelect({
                ...props.dataItem,
                delete: !props.dataItem.delete,
              })
            }
          />
        </td>
      );
    }
  };

  const pageChange = (event) => {
    setSkip(event.page.skip);
  };

  return (
    <>
      <Grid
        style={{
          height: "500px",
        }}
        data={rows}
        filterable={false}
        filter={filter}
        onFilterChange={(e) => setFilter(e.filter)}
        scrollable={"scrollable"}
        total={rows.length}
        pageSize={20}
        skip={skip}
        onPageChange={pageChange}
      >
        {/* <GridToolbar>
          <input type="search" id="site-search" name="q" />
        </GridToolbar> */}
        <Column field="name" title="Endpoint Name" width="400px" />
        <Column field="get" title="GET" cell={getCell} filterable={false} />
        <Column field="post" title="POST" cell={postCell} filterable={false} />       
        <Column field="delete" title="DELETE" cell={deleteCell} filterable={false}/>
      </Grid>
    </>
  );
}

export const MemoizedNewApiScopesTable = React.memo(NewApiScopesTable);
