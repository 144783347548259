import React, { useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import AppLogo from "../../components/AppLogo";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Card,
  CardContent,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { forgotPassword } from "../../services/authService";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
  background-color: #f9f8fd;
`;

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0 1px 5px 2px rgba(0, 0, 0, .2)",
    padding: "1rem",
  },
  signInButton: {
    color: "white",
    backgroundColor: "#1D88E4",
    "&:hover": { backgroundColor: "#176dea" },
  },

  forgotButton: {
    marginTop: "1.2rem",
    color: "#1D88E4",
    "&:hover": { color: "#176dea", background: "none" },
  },
}));

function ForgotPassword() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState("");
  const classes = useStyles();

  return (
    <Wrapper>
      <Helmet title="Forgot Password" />
      <AppLogo mb="20px" />
      <Card className={classes.card}>
        <CardContent>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Forgot Password
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Enter your account email, we'll send you a link reset to your
            password
          </Typography>

          <Formik
            initialValues={{
              email: "",
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .matches(
                  /^([\w-\.]+@(?!bromcom.com)([\w-]+\.)+[\w-]{2,4})?$/,
                  "Bromcom email service will not be allowed."
                )
                .max(255)
                .required("Email is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, resetForm, isSubmitting }
            ) => {
              try {
                const response = await forgotPassword(values.email);
                console.log(response);

                if (response.isSuccess) {
                  setIsSuccess(true);
                  setEnteredEmail(values.email);
                }

                setIsSubmitted(true);
                setSubmitting(false);
                resetForm();
              } catch (error) {
                const message = error.message || "Something went wrong";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                {isSuccess && isSubmitted && (
                  <Alert mt={2} mb={1} severity="success">
                    We'have sent to <strong>{enteredEmail} </strong>
                    with password reset link.Please click on the provided in the
                    mail. You will be redirect to password reset page.
                  </Alert>
                )}

                {!isSuccess && isSubmitted && (
                  <Alert mt={2} mb={1} severity="warning">
                    The email address you entered is not assigned to a
                    registered user. Please check and try again.
                  </Alert>
                )}
                <TextField
                  type="email"
                  name="email"
                  label="Email Address"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                  disabled={isSubmitting}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Reset Password
                </Button>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Wrapper>
  );
}

export default ForgotPassword;
