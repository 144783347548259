import React from "react";
import { useSelector } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import DateFnsUtils from "@date-io/date-fns";

import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { SnackbarProvider } from "notistack";

import createTheme from "./theme";
import Routes from "./routes/Routes";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

function App() {
  const theme = useSelector((state) => state.themeReducer);

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Bromcom"
          defaultTitle="Bromcom Partner Management"
        />
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
              <ThemeProvider theme={createTheme(theme.currentTheme)}>
                <SnackbarProvider
                  maxSnack={3}
                  ref={notistackRef}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  action={(key) => (
                    <Button
                      size="small"
                      variant="contained"
                      onClick={onClickDismiss(key)}
                    >
                      Close
                    </Button>
                  )}
                >
                  <Routes />
                </SnackbarProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </HelmetProvider>
    </React.Fragment>
  );
}

export default App;
