import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField as MuiTextField,
  Tooltip,
  Typography,
  Button,
} from "@material-ui/core";

import { DataGrid } from "@mui/x-data-grid";
import CloseIcon from "@material-ui/icons/Close";
import BlockRoundedIcon from "@material-ui/icons/BlockRounded";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";

import { makeStyles } from "@material-ui/styles";

import PermissionPage from "../../pages/pages/Permisson";
import RegisteredSchools from "../../pages/pages/RegisteredSchools";
import api from "../../api/api";
import { spacing } from "@material-ui/system";
import { useSnackbar } from "notistack";

const TextField = styled(MuiTextField)(spacing);

const Badge = styled.span`
  font-size: 11px;
  background-color: ${(props) => props.color};
  color: white;
  border-radius: 8px;
  padding: 4px 8px;
  text-align: center;
`;

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelIconContainer: {
    position: "relative",
  },
  cancelIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.6",
    },
  },
  container: {
    minHeight: "400px",
  },
  dialogTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px 0  0",
  },
  noRowsOverlay: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noRowsOverlayText: {
    transform: "translateY(40px)",
  },
  registeredSchool: {
    cursor: "pointer",
    borderBottom: "2px solid #000",
    "&:hover": {
      color: "blue",
      borderBottom: "2px solid blue",
    },
  },
  blockButton: {
    color: "white",
    background: "#c71414",
    marginRight: "5px",
    "&:hover": {
      background: "#ff0000",
    },
  },
  unblockButton: {
    color: "white",
    marginRight: "5px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },
  infoIcon: {
    color: "#999999",
    marginLeft: "3px",
  },
}));

const NoRowsOverlay = () => {
  const classes = useStyles();
  return (
    <Box className={classes.noRowsOverlay}>
      <Typography component="p" className={classes.noRowsOverlayText}>
        No Applications Found
      </Typography>
    </Box>
  );
};

export default function PartnerAccountsAppTable({
  applications,
  height,
  changeStatus,
}) {
  const [selectedApp, setSelectedApp] = useState({});
  const [showVersions, setShowVersions] = useState(false);
  const [showRegisteredSchools, setShowRegisteredSchools] = useState(false);
  const [showBlockPage, setShowBlockPage] = useState(false);
  const noteRef = useRef();

  const auth = useSelector((state) => state.authReducer);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const handleCloseVersions = () => {
    setShowVersions(false);
  };

  const handleCloseRegisteredSchools = () => {
    setShowRegisteredSchools(false);
  };

  const handleCloseBlockPage = () => {
    setShowBlockPage(false);
  };

  const handleBlock = async () => {
    if (noteRef.current.value.length === 0) {
      enqueueSnackbar("Please enter your reason for blocking", {
        variant: "warning",
        autoHideDuration: 3000,
      });
    } else {
      api
        .put(
          `api/PartnerApplication/ChangeStatus/${selectedApp?.appId}?v=1.0`,
          {
            description: noteRef.current.value,
            statusId: 3,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.user?.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            handleCloseBlockPage();
            changeStatus();
            enqueueSnackbar("The application has been successfully blocked", {
              variant: "success",
              autoHideDuration: 3000,
            });
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  const handleUnblock = async () => {
    api
      .put(
        `api/PartnerApplication/ChangeStatus/${selectedApp?.appId}?v=1.0`,
        {
          description: "asdasd",
          statusId: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          handleCloseBlockPage();
          changeStatus();
          enqueueSnackbar("The application has been successfully unblocked", {
            variant: "success",
            autoHideDuration: 3000,
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const cols = [
    {
      field: "name",
      headerName: "Application Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        if (params.value === "Publish")
          return <Badge color="#8AC747">{params.value}</Badge>;
        else if (params.value === "Unpublish")
          return <Badge color="#9c9c9c">{params.value}</Badge>;
        else if (params.value === "Blocked") {
          return (
            <>
              <Badge color="#EF233C">{params.value}</Badge>
              <Tooltip title={params.row.adminComment}>
                <InfoRoundedIcon className={classes.infoIcon} />
              </Tooltip>
            </>
          );
        }
      },
    },
    {
      field: "registeredSchool",
      headerName: "Registered Schools",
      flex: 1,
      minWidth: 150,

      renderCell: (params) => {
        const onClick = () => {
          setSelectedApp({
            appId: params.row.id,
            versions: params.row.versions,
          });
          setShowRegisteredSchools(true);
        };

        return (
          <Tooltip title="View Registered Schools">
            <Box
              component="span"
              textAlign="center"
              className={classes.registeredSchool}
              onClick={onClick}
            >
              {params.value}
            </Box>
          </Tooltip>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 110,
      align: "center",

      renderCell: (params) => {
        const handleShowVersions = () => {
          setSelectedApp({
            appId: params.row.id,
            applicationId: params.row.applicationId,
            versions: params.row.versions,
          });
          setShowVersions(true);
        };

        const handleShowBlockPage = () => {
          setSelectedApp({
            appId: params.row.id,
            applicationId: params.row.applicationId,
            versions: params.row.versions,
            status: params.row.status,
            adminComment: params.row.adminComment,
          });
          setShowBlockPage(true);
        };

        return (
          <>
            <Tooltip title="View Versions">
              <IconButton onClick={handleShowVersions}>
                <ViewModuleIcon />
              </IconButton>
            </Tooltip>
            {params.row.status === "Blocked" ? (
              <Tooltip title="Unlock Application">
                <IconButton onClick={handleShowBlockPage}>
                  <LockOpenIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Block Application">
                <IconButton onClick={handleShowBlockPage}>
                  <BlockRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {!showVersions && !showRegisteredSchools && showBlockPage && (
        <Dialog open={showBlockPage} onClose={handleCloseBlockPage}>
          <Box className={classes.dialogTitleContainer}>
            <DialogTitle className={classes.dialogTitle}>
              Confirmation
            </DialogTitle>
            <CloseIcon
              onClick={handleCloseBlockPage}
              className={classes.cancelIcon}
            />
          </Box>
          <DialogContent>
            <Typography variant="body1" component="p">
              Are you sure you want to{" "}
              {selectedApp.status === "Blocked" ? "Unblock" : "Block "} this
              application?
            </Typography>
            {selectedApp.status === "Blocked" ? (
              <TextField
                type="text"
                name="Notes"
                my={3}
                multiline
                rowsMax={5}
                fullWidth
                disabled
                value={selectedApp.adminComment}
              />
            ) : (
              <TextField
                type="text"
                name="Notes"
                label="Type your reason"
                my={3}
                multiline
                rowsMax={5}
                fullWidth
                inputProps={{ maxLength: 225 }}
                inputRef={noteRef}
              />
            )}

            <Box className={classes.buttons}>
              {selectedApp.status === "Blocked" ? (
                <Button
                  type="submit"
                  mr={2}
                  variant="contained"
                  size="small"
                  className={classes.unblockButton}
                  onClick={handleUnblock}
                >
                  Unblock
                </Button>
              ) : (
                <Button
                  type="submit"
                  mr={2}
                  variant="contained"
                  size="small"
                  className={classes.blockButton}
                  onClick={handleBlock}
                >
                  Block
                </Button>
              )}

              <Button
                variant="contained"
                className={classes.cancelButton}
                size="small"
                onClick={handleCloseBlockPage}
              >
                Cancel
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {showVersions && !showRegisteredSchools && !showBlockPage && (
        <Dialog
          open={showVersions}
          onClose={handleCloseVersions}
          maxWidth="md"
          fullWidth
        >
          <Box className={classes.cancelIconContainer}>
            <CloseIcon
              onClick={handleCloseVersions}
              className={classes.cancelIcon}
            />
          </Box>
          <DialogContent className={classes.container}>
            <PermissionPage selectedApp={selectedApp} />
          </DialogContent>
        </Dialog>
      )}

      {!showVersions && showRegisteredSchools && !showBlockPage && (
        <Dialog
          open={showRegisteredSchools}
          onClose={handleCloseRegisteredSchools}
          maxWidth="lg"
          fullWidth
        >
          <Box className={classes.cancelIconContainer}>
            <CloseIcon
              onClick={handleCloseRegisteredSchools}
              className={classes.cancelIcon}
            />
          </Box>
          <DialogContent className={classes.container}>
            <RegisteredSchools selectedApp={selectedApp} />
          </DialogContent>
        </Dialog>
      )}

      <div style={{ height: height, width: "100%" }}>
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },

            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
          rows={applications ? applications : []}
          columns={cols}
          rowsPerPageOptions={[10, 50, 100]}
          disableSelectionOnClick
          components={{
            NoRowsOverlay: NoRowsOverlay,
          }}
          scrollbarSize={30}
          onRowDoubleClick={(e) => {
            setSelectedApp({
              appId: e.row.id,
              applicationId: e.row.applicationId,
              versions: e.row.versions,
            });
            setShowRegisteredSchools(true);
          }}
          hideFooterPagination
        />
      </div>
    </>
  );
}
