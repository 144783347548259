import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import ApplicationTable from "../../components/tables/ApplicationTable";
import CreateApplication from "./CreateApplication";
import { Add as AddIcon } from "@material-ui/icons";
import api from "../../api/api";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme) => ({
  createButton: {
    color: "white",
    padding: "8px 20px",
    background: "#7FB741",
    "&:hover": {
      background: "#8AC747",
    },
  },
}));
export default function Applications() {
  const [applications, setApplications] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showCreateApplication, setshowCreateApplication] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const auth = useSelector((state) => state.authReducer);
  const history = useHistory();

  const classes = useStyles();

  const handleShowCreateApplication = () => {
    //setshowCreateApplication(true);
    history.push("/createapplication");
  };

  const handleIsSubmitted = () => {
    setIsSubmitted(!isSubmitted);
  };

  useEffect(() => {
    api
      .get(`api/PartnerApplication/List?${auth.user?.pid}v=1.0`, {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setApplications(response.data.data);
          setIsLoading(false);
        }
        if (response.status === 204) {
          setIsLoading(false);
          setError({ message: "No data found" });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  }, [isSubmitted]);

  if (isLoading) return <Loader />;
  if (error) return <div style={{ color: "red" }}>{error.message}</div>;

  return (
    <React.Fragment>
      <Helmet title="Applications" />

      <Grid justify="space-between" container spacing={4}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Applications
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Pages</Typography>
            <Typography>Applications</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          {!(auth?.user?.rol === "PartnerSupport") && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleShowCreateApplication}
              disabled={showCreateApplication}
              className={classes.createButton}
            >
              <AddIcon />
              Create New Application
            </Button>
          )}
        </Grid>
      </Grid>

      <Divider my={6} />

      {!showCreateApplication && (
        <ApplicationTable
          applications={applications}
          isSubmitted={handleIsSubmitted}
        />
      )}
      {/* {showCreateApplication && (
        <CreateApplication isSubmitted={handleIsSubmitted} />
      )} */}
    </React.Fragment>
  );
}
