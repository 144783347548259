import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
  Box,
  Button as MuiButton,
  CircularProgress,
  Dialog,
  DialogContent,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import api from "../../api/api";
import Loader from "../../components/Loader";
import { Alert } from "@material-ui/lab";
import AppLogo from "../../components/AppLogo";
import { useSnackbar } from "notistack";

const Button = styled(MuiButton)(spacing);

const Centered = styled.div`
  width: 100%;

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px 2rem 10px;
  margin: 1rem 0;
`;

const InnerBox = styled.div`
  text-align: center;
  display: flex;
  justify-content: flex-start;
`;
const Buttons = styled.div`
  display: flex;
`;

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "55%",
    minHeight: "500px",
    padding: "2rem",
    background: "white",
    borderRadius: "5px",
    boxShadow: "0 0 5px 1px rgba(0,0,0,.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
    [theme.breakpoints.down("960")]: {
      width: "80%",
      padding: "2rem 0",
    },
  },
  successButton: {
    width: "120px",
    color: "white",
    padding: "5px 20px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },
  rejectButton: {
    width: "120px",
    color: "white",
    padding: "5px 20px",
    background: "#c71414",
    "&:hover": {
      background: "#ff0000",
    },
  },
  popupButtons: {
    padding: "5px",
    marginTop: "10px",
  },
  okButton: {
    width: "80px",
    color: "white",
    padding: "5px 20px",
    background: "#0b990b",
    "&:hover": {
      background: "#25b425",
    },
  },
  cancelButton: {
    width: "80px",
    color: "white",
    padding: "5px 20px",
    background: "#c71414",
    "&:hover": {
      background: "#ff0000",
    },
  },
  infoBoxContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  infoBox: {
    width: "80%",
  },
  infoBoxLink: {
    marginTop: "10px",
    textAlign: "center",
    cursor: "pointer",
    color: "blue",
    borderBottom: "1px solid #0363ff",
    "&:hover": {
      color: "#0054db",
      borderBottom: "1px solid #0054db",
    },
  },
}));

function Roles({ close, handleApprove, isSending }) {
  const classes = useStyles();
  const [roles, setRoles] = React.useState([]);
  const [selectedRoles, setSelectedRoles] = React.useState("");

  const handleOk = () => {
    handleApprove(selectedRoles);
  };
  const handleCancel = () => {
    close();
  };
  const handleChange = (event) => {
    setSelectedRoles(event.target.value);
  };

  useEffect(() => {
    api
      .get("api/User/Roles?v=1.0")
      .then((response) => {
        if (response.status === 200) {
          setRoles(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Roles</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedRoles}
          label="Roles"
          onChange={handleChange}
        >
          {roles.map((role) => (
            <MenuItem keyt={role.id} value={role.name}>
              {role?.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box className={classes.popupButtons}>
        <Button
          mr={2}
          variant="contained"
          className={classes.okButton}
          onClick={handleOk}
        >
          {isSending ? (
            <CircularProgress size={18} style={{ color: "white" }} />
          ) : (
            "OK"
          )}
        </Button>
        <Button
          variant="contained"
          className={classes.cancelButton}
          onClick={handleCancel}
          disabled={isSending}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function PartnerPermissionRequest() {
  const [info, setInfo] = useState({});
  const [open, setOpen] = useState(false);
  const [partner, setPartner] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isSending, setIsSending] = useState(false);
  const [isSendingReject, setIsSendingReject] = useState(false);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const query = useQuery();
  const email = query.get("email");
  let token = query.get("token");

  if (!email || !token) {
    window.location.href = "/";
  } else {
    token = token.replaceAll(" ", "+");
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = (roles) => {
    if (roles) {
      setIsSending(true);
      api
        .post("api/Partner/PermissionApprove?v=1.0", {
          email: partner?.registerForm?.email,
          token: partner?.token,
          roles: [roles],
        })
        .then((response) => {
          if (response.data.isSuccess) {
            setInfo({
              message: "Successfull",
              alertMesage: "success",
              result: "successfull",
            });
            setIsSending(false);
            handleClose();
          }
        })
        .catch((error) => {
          setError(error.message);
          setIsSending(false);
          handleClose();
        });
    } else {
      enqueueSnackbar("Please select a role", {
        variant: "warning",
        autoHideDuration: 3000,
      });
    }
  };

  useEffect(() => {
    api
      .post("api/Partner/PermissionVerify?v=1.0", {
        email: email,
        token: token,
      })
      .then((response) => {
        if (response.data.isSuccess) {
          setPartner(response.data.data);
          setIsLoading(false);
          setInfo({
            message: "",
            alertMesage: "",
            result: "success",
          });
        } else if (
          (response.data.errorMessage = "Permission Request Not exist")
        ) {
          setInfo({
            message: "Permission Request Not exist",
            alertMesage: "error",
            result: "request not exist",
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  }, []);

  const handleApprovePartnerRequest = () => {
    setOpen(true);
  };
  const handleRejectPartnerRequest = () => {
    setIsSendingReject(true);
    api
      .post("api/Partner/PermissionReject?v=1.0", {
        email: partner?.registerForm?.email,
        token: partner?.token,
      })
      .then((response) => {
        setInfo({
          message: "Rejected",
          alertMessage: "warning",
          result: "rejected",
        });
        setIsSendingReject(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsSendingReject(false);
      });
  };

  if (isLoading) return <Loader />;
  if (error) return <div style={{ color: "red" }}>{error}</div>;

  return (
    <Box className={classes.container}>
      <AppLogo mb="40px" className={classes.logo} />
      <Box marginBottom={3}>
        <Typography
          id="modal-modal-title"
          variant="h4"
          component="h3"
          gutterBottom
          align="center"
        >
          Partner Permission Request
        </Typography>
      </Box>
      {info.result === "request not exist" ? (
        <Box className={classes.infoBoxContainer}>
          <Alert severity="warning" className={classes.infoBox}>
            {info.message}
          </Alert>
          <span
            className={classes.infoBoxLink}
            onClick={() => {
              window.location = "/";
            }}
          >
            Click to go to homepage
          </span>
        </Box>
      ) : (
        <Centered>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              Organization:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.registerForm?.organizationName}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              First Name:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.registerForm?.firstName}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              Last Name:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.registerForm?.lastName}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              Email:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.registerForm?.email}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              Telephone Number :
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.registerForm?.phone}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              Website:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.registerForm?.webSite}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              Role:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.registerForm?.role}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              No of Bromcom Customers:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.registerForm?.customerCount}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              Services Provided to Bromcom Customers:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.registerForm?.servicesProvidedToBC}
            </Box>
          </InnerBox>
          <InnerBox>
            <Box
              component="b"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              Planned Integrations:
            </Box>
            <Box
              component="span"
              marginRight={2}
              marginBottom={2}
              textAlign="left"
            >
              {partner?.registerForm?.plannedIntegrations}
            </Box>
          </InnerBox>
        </Centered>
      )}

      {info.result === "success" && (
        <Buttons>
          <Button
            mr={2}
            variant="contained"
            className={classes.successButton}
            onClick={handleApprovePartnerRequest}
            disabled={isSendingReject}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            className={classes.rejectButton}
            onClick={handleRejectPartnerRequest}
          >
            {isSendingReject ? (
              <CircularProgress size={18} style={{ color: "white" }} />
            ) : (
              "Reject"
            )}
          </Button>
        </Buttons>
      )}

      {info.result === "successfull" && (
        <Box className={classes.infoBoxContainer}>
          <Alert severity={info.alertMessage} className={classes.infoBox}>
            {info.message}
          </Alert>
          <span
            className={classes.infoBoxLink}
            onClick={() => {
              window.location = "/";
            }}
          >
            Click to go to homepage
          </span>
        </Box>
      )}

      {info.result === "rejected" && (
        <Box className={classes.infoBoxContainer}>
          <Alert severity={info.alertMessage} className={classes.infoBox}>
            {info.message}
          </Alert>
          <span
            className={classes.infoBoxLink}
            onClick={() => {
              window.location = "/";
            }}
          >
            Click to go to homepage
          </span>
        </Box>
      )}

      {open && (
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <Roles
              close={handleClose}
              handleApprove={handleApprove}
              isSending={isSending}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}

export default PartnerPermissionRequest;
