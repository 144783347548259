import * as types from "../../constants";

const initialState = {
  user: {},
  isLoading: true,
  data: "",
  message: "",
  isSuccess: "",
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_UP_SUCCESS:
      return {
        ...state,
        user: {
          email: actions.email,
          website: actions.website,
        },
      };
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: {
          email: actions.email,
          id: actions.id,
          rol: actions.rol,
          pid: actions.pid,
          token: actions.token,
          firstName: actions.firstName,
          lastName: actions.lastName,
          companyName: actions.companyName,
          desc: actions.desc,
          exp: actions.exp,
          companyLogo: actions.companyLogo,
        },
        data: actions.data,
        message: actions.message,
        isSuccess: actions.isSuccess,
        isLoading: false,
      };

    case types.AUTH_SIGN_IN_FAILED:
      return {
        ...state,
        user: undefined,
        isLoading: false,
        data: actions.data,
        message: actions.message,
        isSuccess: actions.isSuccess,
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: undefined,
        message: actions.message,
      };

    default:
      return state;
  }
}
