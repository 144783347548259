import React from "react";
import styled from "styled-components/macro";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const apiDocumentationURL = process.env.REACT_APP_DATA_API_DOCUMENT_URL;

const Wrapper = styled("div")`
  padding: 0 10px;
  background-color: white;
`;

function ApiDocumentation() {
  return (
    <React.Fragment>
      <Helmet title="Api Documentation" />

      <Typography variant="h3" gutterBottom display="inline">
        Api Documentation
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Typography>Pages</Typography>
        <Typography>Api Documentation</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Wrapper>
        <Grid container alignItems="center" justify="flex-start">
          <Grid item md={12}>
            <SwaggerUI url={apiDocumentationURL} />
          </Grid>
        </Grid>
      </Wrapper>
    </React.Fragment>
  );
}

export default ApiDocumentation;
