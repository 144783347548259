import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import ManageTeamTable from "../../components/tables/ManageTeamTable";
import { Helmet } from "react-helmet-async";
import api from "../../api/api";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Grid,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import Loader from "../../components/Loader";
import { Add as AddIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import CreateUser from "./CreateMember";
import { useHistory } from "react-router-dom";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme) => ({
  createButton: {
    color: "white",
    padding: "8px 20px",
    background: "#7FB741",
    "&:hover": {
      background: "#8AC747",
    },
  },
}));

function ManageTeam() {
  const [users, setUsers] = useState([]);
  const [showCreateMember, setshowCreateMember] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();

  const classes = useStyles();

  const auth = useSelector((state) => state.authReducer);

  const handleShowCreateMember = () => {
    // setshowCreateMember(true);
    history.push("/createmember");
  };

  const handleIsSubmitted = () => {
    setIsSubmitted(!isSubmitted);
  };

  useEffect(() => {
    api
      .get("api/User?v=1.0", {
        headers: {
          Authorization: `Bearer ${auth.user?.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setUsers(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  }, [isSubmitted]);

  if (isLoading) return <Loader />;
  if (error) return <div style={{ color: "red" }}>{error}</div>;

  return (
    <React.Fragment>
      <Helmet title="ManageTeam" />

      <Grid justify="space-between" container spacing={4}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Manage Team
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography>Pages</Typography>
            <Typography>Manage Team</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleShowCreateMember}
            disabled={showCreateMember}
            className={classes.createButton}
          >
            <AddIcon />
            Create New Member
          </Button>
        </Grid>
      </Grid>
      <Divider my={6} />
      {!showCreateMember && (
        <ManageTeamTable
          users={users}
          isSubmitted={handleIsSubmitted}
          showCreateMamber={handleShowCreateMember}
        />
      )}
      {showCreateMember && <CreateUser />}
    </React.Fragment>
  );
}

export default ManageTeam;
