import * as types from "../../constants";

export function setPartner(partner) {
  return {
    type: types.PARTNER_SET,
    partner: partner,
  };
}

export function setActivePartner(partner) {
  return {
    type: types.PARTNER_SUCCESS,
    activePartner: partner,
  };
}

export function setPartnerStatus(status) {
  return {
    type: types.PARTNER_STATUS,
    partnerStatus: status,
  };
}

export function setAllPartners(partners) {
  return {
    type: types.PARTNER_STATUS,
    partners: partners,
  };
}
