import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function GuestGuard({ children }) {
  const auth = useSelector((state) => state.authReducer);
  const token = auth?.user?.token;
  const rol = auth?.user?.rol;

  const query = useQuery();
  const returnUrl = query.get("returnurl");

  if (token) {
    if (rol.startsWith("Bromcom")) {
      if (returnUrl) {
        const urls = returnUrl.split("/");
        const url = urls[urls.length - 1];
        const newUrl = `/supportticketdetail/${url}`;
        return <Redirect to={newUrl} />;
      } else {
        return <Redirect to="/getting-started" />;
      }
    } else {
      return <Redirect to="/getting-started" />;
    }
  }

  return children;
}

export default GuestGuard;
