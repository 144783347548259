import * as types from "../../constants";

export function setRowsPerPage(size) {
  return {
    type: types.GRID_ROWS_PER_PAGE,
    size,
  };
}

export function setPage(page) {
  return {
    type: types.GRID_PAGE,
    page,
  };
}

export function setIsGridLoading(isLoading, totalCount) {
  return {
    type: types.GRID_ISLOADING,
    isGridLoading: isLoading,
    totalCount: totalCount,
  };
}
