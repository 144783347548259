import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import Moment from "react-moment";

import IconButton from "@material-ui/core/IconButton";

import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";

import VisibilityIcon from "@material-ui/icons/Visibility";

import QuickSearchToolbar from "./QuickSearchToolbar";
import { useDispatch, useSelector } from "react-redux";
import { setPage, setRowsPerPage } from "../../redux/actions/datagridActions";
import api from "../../api/api";

const Badge = styled.span`
  font-size: 11px;
  background-color: ${(props) => props.color};
  color: white;
  border-radius: 8px;
  padding: 4px 8px;
  text-align: center;
`;

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

function capitalizeFirstLetter(str) {
  return str[0].toUpperCase() + str.slice(1);
}

export default function SupportTicketTable({ tickets }) {
  const dispatch = useDispatch();

  const history = useHistory();
  const auth = useSelector((state) => state.authReducer);
  const gridState = useSelector((state) => state.datagridReducer);

  const [searchText, setSearchText] = useState("");
  const [filterValue, setFilterValue] = useState(true);
  const [rows, setRows] = useState(tickets);
  const [filt, setFilt] = useState([])

  const onFilterChange = React.useCallback((filterModel) => {
    if (
      filterModel.items[0].value &&
      capitalizeFirstLetter(filterModel.items[0].value) === "Open"
    ) {
      setFilterValue(true);
    } else if (
      filterModel.items[0].value &&
      capitalizeFirstLetter(filterModel.items[0].value) === "Close"
    ) {
      setFilterValue(false);
    } else {
      setFilterValue("");
    }
  }, []);

  const [sortModel, setSortModel] = useState([
    {
      field: "createdDate",
      sort: "desc",
    },
  ]);

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage));
  };

  const handlePageSizeChange = (size) => {
    dispatch(setRowsPerPage(size));
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    api
      .get(
        `api/SupportTicket?SupportTicketStatusId=${filterValue}&Keyword=${searchValue}&PageSize=${
          gridState?.size
        }&PageNumber=${gridState?.page + 1}&v=1.0`,
        {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setRows(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    setRows(tickets);
  }, [tickets]);

  useEffect(() => {
    let active = true;
    api
      .get(
        `api/SupportTicket?SupportTicketStatusId=${filterValue}&Keyword=${searchText}&PageSize=${
          gridState?.size
        }&PageNumber=${gridState?.page + 1}&v=1.0`,
        {
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (!active) {
            return;
          }
          if(filterValue === "")
            setFilt([]);
          else
            setFilt([{ columnField: "supportTicketStatusId", operatorValue: "is", value: (filterValue ? "Open" : "Close")}]);
          setRows(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
    return () => {
      active = false;
    };
  }, [filterValue]);

  const cols = [
    { field: "id", headerName: "ID", width: 80, filterable: false },
    {
      field: "fullName",
      headerName: "RequestedBy",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "title",
      headerName: "Subject",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => (
        <Moment date={params.value} format="DD/MM/YYYY HH:mm:ss" />
      ),
    },
    {
      field: "replyDateText",
      headerName: "Reply Date",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "replyName",
      headerName: "Reply Name",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },

    {
      field: "supportTicketStatusId",
      headerName: "Status",
      width: 100,
      align: "center",
      valueOptions: [
        { label: 'Open', value: 'Open' },
        { label: 'Closed', value: 'Close' }
      ],
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
      ),
      renderCell: (params) => {
        let color;
        let text;
        if (params.value) {
          color = "#22b9e3";
          text = "Open";
        } else {
          color = "#9c9c9c";
          text = "Closed";
        }
        return <Badge color={color}>{text}</Badge>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      align: "center",
      filterable: false,

      renderCell: (params) => {
        const onClick = () => {
          history.push(`/supportticketdetail/${params.row.id}`);
        };

        return (
          <IconButton onClick={onClick}>
            <VisibilityIcon />
          </IconButton>
        );
      },
    },
  ];

  if (
    [
      "BromcomSuperUser",
      "BromcomAdmin",
      "BromcomDeveloper",
      "BromcomSupport",
    ].includes(auth?.user?.rol)
  ) {
    cols.splice(4, 0, {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      minWidth: 100,
      filterable: false,
    });

    cols.splice(7, 0, {
      field: "assignedUserName",
      headerName: "Assigned To",
      flex: 1,
      minWidth: 100,
      filterable: false,
    });
  }

  return (
    <div style={{ height: 760, width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },

          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        components={{ Toolbar: QuickSearchToolbar }}
        rows={rows}
        columns={cols}
        sortModel={sortModel}
        rowsPerPageOptions={[10, 50, 100]}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(" "),
          },
        }}
        filterModel={{ items: filt}}
        onFilterModelChange={onFilterChange}
        filterMode="server"
        onSortModelChange={(model) => setSortModel(model)}
        disableSelectionOnClick
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        scrollbarSize={30}
        onRowDoubleClick={(e) => {
          history.push(`/supportticketdetail/${e.row.id}`);
        }}
        paginationMode="server"
        rowCount={gridState.totalCount}
        loading={gridState.isGridLoading}
      />
    </div>
  );
}
